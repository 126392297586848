import { mapActions, mapGetters } from 'vuex';
import { queries } from '@/modules/apollo-queries/poa-meta';
import { error } from '@/mixins/apollo';

import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import GET_POA_STATUS from '@/graphql/queries/GetPoaStatus';

export default {
  name: 'MixinsApolloPoa',
  apollo: {
    'About Yourself': {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
    },
    Financial: {
      ...queries.poaFinancialMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        if (data?.poa) {
          this.setPoaData(data.poa);
        } else {
          console.error('Unexpected data structure for data.poa:', data);
        }
      },
    },
    Medical: {
      ...queries.poaMedicalMeta,
      variables() {
        return {
          poaId: this.poaId,
        };
      },
      result({ data }) {
        if (data?.poa) {
          this.setPoaData(data.poa);
        } else {
          console.error('Unexpected data structure for data.poa:', data);
        }
      },
    },
    poaStatus: {
      query: GET_POA_STATUS,
      fetchPolicy: 'network-only',
      update: (data) => {
        return data.poa !== null ? data.poa.status : null;
      },
      variables() {
        return { id: this.poaId };
      },
      result({ data }) {
        if (data?.poa?.status) {
          this.setPoaStatus(data.poa?.status);
        } else {
          console.error('Unexpected data structure for data.poa:', data);
        }
      },
      error,
    },
  },
  computed: {
    ...mapGetters(['willId']),
  },
  methods: {
    ...mapActions('user/poa', ['setPoaStatus', 'setPoaData']),
  },
};
