import _ from 'lodash';

export const getMockCompletePoaProgress = (mockCompletePoaProgress) =>
  _.merge(
    {
      complete: true,
      decimal: 1,
      modules: {
        aboutYourself: {
          score: 3,
          target: 3,
          decimal: 1,
          icon: 'completed',
          class: '--complete',
        },
        financial: {
          score: 8,
          target: 8,
          decimal: 1,
          icon: 'completed',
          class: '--complete',
        },
        medical: {
          score: 8,
          target: 8,
          decimal: 1,
          icon: 'completed',
          class: '--complete',
        },
        review: {
          score: 1,
          target: 1,
          decimal: 1,
          icon: 'completed',
          class: '--complete',
        },
      },
    },
    mockCompletePoaProgress
  );

export const getMockCompletePoaRootGetters = (mockPoaRootGetters) =>
  _.merge(
    {
      'user/poa/status': 'AWAITING_APPROVAL',
      'user/poa/data': {
        meta: {
          financial: {
            has_attorney: true,
            has_substitute: false,
            has_inclusions: true,
            has_limitations: false,
          },
          medical: {
            has_attorney: true,
            has_substitute: false,
            has_limitations: false,
            has_preferences: false,
          },
        },
      },
      'user/contacts/userDetails': {
        firstName: 'Apple',
        lastName: 'Banana',
        dateOfBirth: '2000-02-29',
        residentialAddress: {
          streetAddress: '1 Street',
          locality: 'Alocality',
          region: 'nsw',
          postcode: '2023',
          country: 'AU',
        },
      },
      'user/poa/attorneys': {
        financial: {
          primary: [
            {
              id: 'c3317dc7-714c-490d-8382-29c54272c6d3',
              meta: [],
              type: 'primary',
              directoryPerson: {
                id: '295f8183-d138-4440-a887-b7a0f4fb3382',
                ownerId: '1cbccd72-4280-4c40-a798-3a88a580d28e',
                firstName: 'Apple',
                middleName: null,
                lastName: 'Banana',
                altFirstName: null,
                altMiddleName: null,
                altLastName: null,
                dateOfBirth: '1911-01-01',
                countryOfBirth: null,
                emails: [],
                phoneNumbers: [],
                ageEighteenOrAbove: true,
                residentialAddress: {
                  streetAddress: '',
                  extendedAddress: '',
                  locality: '',
                  postcode: '',
                  region: '',
                  country: '',
                  __typename: 'DirectoryAddress',
                },
                relationships: null,
                createdAt: '1696315056898',
                __typename: 'DirectoryPerson',
              },
              __typename: 'Executor',
            },
          ],
        },
        medical: {
          primary: [
            {
              id: 'c3317dc7-714c-490d-8382-29c54272c6d3',
              meta: [],
              type: 'primary',
              directoryPerson: {
                id: '295f8183-d138-4440-a887-b7a0f4fb3382',
                ownerId: '1cbccd72-4280-4c40-a798-3a88a580d28e',
                firstName: 'Apple',
                middleName: null,
                lastName: 'Banana',
                altFirstName: null,
                altMiddleName: null,
                altLastName: null,
                dateOfBirth: '1911-01-01',
                countryOfBirth: null,
                emails: [],
                phoneNumbers: [],
                ageEighteenOrAbove: true,
                residentialAddress: {
                  streetAddress: '',
                  extendedAddress: '',
                  locality: '',
                  postcode: '',
                  region: '',
                  country: '',
                  __typename: 'DirectoryAddress',
                },
                relationships: null,
                createdAt: '1696315056898',
                __typename: 'DirectoryPerson',
              },
              __typename: 'Executor',
            },
          ],
        },
      },
      'user/poa/inclusions': [
        {
          id: '7e45702e-1983-4ca9-a230-f7e83bb5c268',
          meta: {
            estate_backup_split: 'children',
          },
          directoryPerson: {
            id: '295f8183-d138-4440-a887-b7a0f4fb3382',
            ownerId: '1cbccd72-4280-4c40-a798-3a88a580d28e',
            firstName: 'Apple',
            middleName: null,
            lastName: 'Banana',
            altFirstName: null,
            altMiddleName: null,
            altLastName: null,
            dateOfBirth: '1911-01-01',
            countryOfBirth: null,
            emails: [],
            phoneNumbers: [],
            ageEighteenOrAbove: true,
            residentialAddress: {
              streetAddress: '',
              extendedAddress: '',
              locality: '',
              postcode: '',
              region: '',
              country: '',
              __typename: 'DirectoryAddress',
            },
            relationships: null,
            createdAt: '1696315056898',
            __typename: 'DirectoryPerson',
          },
          charity: null,
          distribution: null,
          isBackupEstateSplitEvenly: true,
          type: 'PRIMARY',
          backup: [],
          __typename: 'Beneficiary',
        },
      ],
    },
    mockPoaRootGetters
  );

export const getMockCompleteWillRootGetters = (mockRootGetters) =>
  _.merge(
    {
      willStatus: 'AWAITING_APPROVAL',
      'will/data': {
        isPrimaryEstateSplitEvenly: true,
        meta: {
          has_alt_name: false,
          has_partner: false,
          executors_option: 'friendsFamily',
          professional_executor_terms_agreed_at: null,
          has_backup_executor: false,
          estate_split: 'custom',
          estate_backup_split: null,
          has_assets: false,
          has_liabilities: false,
          has_children: false,
          has_primary_guardian: false,
          has_backup_guardian: false,
          has_pets: false,
          has_pet_guardians: false,
          has_gifts: false,
          charity_in_gifts: false,
          funeral_type: 'executor',
        },
      },
      'user/contacts/userDetails': {
        firstName: 'Apple',
        lastName: 'Banana',
        dateOfBirth: '2000-02-29',
        residentialAddress: {
          streetAddress: '1 Street',
          locality: 'Alocality',
          region: 'Regionini',
          postcode: '2023',
          country: 'AU',
        },
      },
      'user/contacts/userPartner': {},
      'will/executors': [
        {
          id: 'c3317dc7-714c-490d-8382-29c54272c6d3',
          meta: [],
          type: 'primary',
          directoryPerson: {
            id: '295f8183-d138-4440-a887-b7a0f4fb3382',
            ownerId: '1cbccd72-4280-4c40-a798-3a88a580d28e',
            firstName: 'Apple',
            middleName: null,
            lastName: 'Banana',
            altFirstName: null,
            altMiddleName: null,
            altLastName: null,
            dateOfBirth: '1911-01-01',
            countryOfBirth: null,
            emails: [],
            phoneNumbers: [],
            ageEighteenOrAbove: true,
            residentialAddress: {
              streetAddress: '',
              extendedAddress: '',
              locality: '',
              postcode: '',
              region: '',
              country: '',
              __typename: 'DirectoryAddress',
            },
            relationships: null,
            createdAt: '1696315056898',
            __typename: 'DirectoryPerson',
          },
          __typename: 'Executor',
        },
      ],
      'will/beneficiaries': [
        {
          id: '7e45702e-1983-4ca9-a230-f7e83bb5c268',
          meta: {
            estate_backup_split: 'children',
          },
          directoryPerson: {
            id: '295f8183-d138-4440-a887-b7a0f4fb3382',
            ownerId: '1cbccd72-4280-4c40-a798-3a88a580d28e',
            firstName: 'Apple',
            middleName: null,
            lastName: 'Banana',
            altFirstName: null,
            altMiddleName: null,
            altLastName: null,
            dateOfBirth: '1911-01-01',
            countryOfBirth: null,
            emails: [],
            phoneNumbers: [],
            ageEighteenOrAbove: true,
            residentialAddress: {
              streetAddress: '',
              extendedAddress: '',
              locality: '',
              postcode: '',
              region: '',
              country: '',
              __typename: 'DirectoryAddress',
            },
            relationships: null,
            createdAt: '1696315056898',
            __typename: 'DirectoryPerson',
          },
          charity: null,
          distribution: null,
          isBackupEstateSplitEvenly: true,
          type: 'PRIMARY',
          backup: [],
          __typename: 'Beneficiary',
        },
      ],
      'will/assets': [],
      'will/liabilities': [],
      'user/contacts/userChildren': [],
      'will/guardians': [],
      'user/pets/pets': [],
      'will/gifts': [],
      'user/flags/isPlanModuleFlagged': () => false,
    },
    mockRootGetters
  );
export const getMockEmptyRootGetters = (mockRootGetters) =>
  _.merge(
    {
      'will/data': { meta: {} },
      'user/contacts/userDetails': {
        firstName: null,
        lastName: null,
        altFirstName: null,
        altLastName: null,
        dateOfBirth: null,
        residentialAddress: {
          streetAddress: null,
          locality: null,
          region: null,
          postcode: null,
          country: null,
        },
      },
      'user/contacts/userPartner': {},
      'will/executors': [],
      'will/beneficiaries': [],
      'will/assets': [],
      'will/liabilities': [],
      'user/contacts/userChildren': [],
      'will/guardians': [],
      'user/pets/pets': [],
      'will/gifts': [],
      'user/flags/isPlanModuleFlagged': () => false,
      'user/poa/data': { meta: {} },
      'user/poa/attorneys': [],
      'user/poa/inclusions': [],
      poaStatus: 'NOT_STARTED',
      willStatus: 'NOT_STARTED',
    },
    mockRootGetters
  );

export const getMockWillProgress = (mockWillProgress) =>
  _.merge(
    {
      complete: false,
      decimal: 0,
      modules: {
        aboutYourself: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        guardians: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        estate: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        gifts: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        assets: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        executors: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        funeral: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        review: {
          score: 0,
          target: 99,
          decimal: 0,
        },
      },
    },
    mockWillProgress
  );

export const getMockPoaProgress = (mockPoaProgress) =>
  _.merge(
    {
      complete: false,
      decimal: 0,
      modules: {
        aboutYourself: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        financial: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        medical: {
          score: 0,
          target: 99,
          decimal: 0,
        },
        review: {
          score: 0,
          target: 99,
          decimal: 0,
        },
      },
    },
    mockPoaProgress
  );

const defaultProgressStateMock = {
  will: getMockWillProgress(),
  poa: getMockPoaProgress(),
};

export const getProgressStateMock = (customState) =>
  _.merge(
    {
      ...defaultProgressStateMock,
    },
    customState
  );
