import GET_PRODUCTS from '@/graphql/queries/Products';

export const state = () => ({
  products: [],
});

export const getters = {
  products: (state) => state.products,
  executorProducts: (state) =>
    state.products.filter((product) => product.category === 'EXECUTOR'),
};

export const mutations = {
  SET_PRODUCTS(state, data) {
    state.products = data;
  },
};

export const actions = {
  async getProducts({ rootState, _, commit }) {
    const {
      data: { products },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_PRODUCTS,
      variables: {
        userId: rootState.userId,
      },
    });
    commit('SET_PRODUCTS', products);
  },
};
