import GET_INVITES_QUERY from '@/graphql/queries/GetInvites';
import GET_PUBLIC_INVITE_QUERY from '@/graphql/queries/GetPublicInvite';
import GET_ACTIVE_INVITES_FOR_RECIPIENT from '@/graphql/queries/GetActiveInvitesForRecipient';
import { is24HoursFromNow } from '@/utilities';

export const state = () => ({
  publicInviteCode: null,
  invites: [],
  activeInvites: [],
});

export const getters = {
  publicInviteCode: (state) => state.publicInviteCode,
  activeInvites: (state) =>
    state.activeInvites.map((invite) => ({
      ...invite,
      discountProductCodes: invite.discountProducts.map(
        (discountProduct) => discountProduct.product.code
      ),
    })),
  invites: (state) =>
    state.invites.map((invite) => ({
      ...invite,
      is24HoursFromNow: is24HoursFromNow(invite.remindedAt),
      discountProductCodes: invite.discountProducts.map(
        (discountProduct) => discountProduct.product.code
      ),
    })),
  invitedEmails: (state) => {
    return state.invites.map((invite) => invite.email);
  },
};
export const mutations = {
  SET_PUBLIC_INVITE_CODE(state, data) {
    state.publicInviteCode = data;
  },
  SET_ACTIVE_INVITES(state, data) {
    state.activeInvites = data;
  },
  SET_INVITES(state, data) {
    state.invites = data;
  },
};

export const actions = {
  async getPublicInviteCode({ state, commit, rootState }, hasFirstName) {
    if (!state.publicInviteCode && hasFirstName) {
      const {
        data: {
          getPublicInvite: { code },
        },
      } = await this.app.apolloProvider.defaultClient.query({
        query: GET_PUBLIC_INVITE_QUERY,
        variables: {
          userId: rootState.userId,
        },
      });
      commit('SET_PUBLIC_INVITE_CODE', code || null);
    }
  },
  async getActiveInvitesForRecipient({ commit, rootGetters }) {
    if (!rootGetters.userId) {
      commit('SET_ACTIVE_INVITES', []);
      return [];
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_ACTIVE_INVITES_FOR_RECIPIENT,
      variables: {
        userId: rootGetters.userId,
      },
    });
    const activeInvites = data?.getActiveInvitesForRecipient ?? [];
    commit('SET_ACTIVE_INVITES', activeInvites);
    return activeInvites;
  },
  async getInvites({ commit, rootGetters }) {
    if (!rootGetters.userId) {
      commit('SET_INVITES', []);
      return [];
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_INVITES_QUERY,
      fetchPolicy: 'no-cache',
      variables: {
        userId: rootGetters.userId,
      },
    });
    const invites = data?.getInvites ?? [];
    commit('SET_INVITES', invites);
    return invites;
  },
};
