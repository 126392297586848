/* c8 ignore start */
const WILL_MODULE_PATH_MAPPING = {
  aboutYourself: '/will/about-yourself',
  guardians: '/will/guardians',
  estate: '/will/estate',
  gifts: '/will/gifts',
  assets: '/will/assets',
  executors: '/will/executors',
  funeral: '/will/funeral',
  review: '/will/review',
};

const POA_MODULE_PATH_MAPPING = {
  aboutYourself: '/poa/about-yourself',
  financial: '/poa/financial',
  medical: '/poa/medical',
};

const WILL_MODULES = [
  {
    id: 'about-yourself',
    key: 'aboutYourself',
    name: 'About Yourself',
    flagKey: 'ABOUT_YOURSELF',
    path: WILL_MODULE_PATH_MAPPING.aboutYourself,
    displayName: 'modules.will.aboutYourself.displayName',
    description: 'modules.will.aboutYourself.description',
    duration: [2, 4],
    totalSteps: 5,
    steps: ['name', 'date-of-birth', 'address', 'partner', 'phone'],
  },
  {
    id: 'guardians',
    key: 'guardians',
    name: 'Guardians',
    flagKey: 'FAMILY',
    path: WILL_MODULE_PATH_MAPPING.guardians,
    displayName: 'modules.will.guardians.displayName',
    description: 'modules.will.guardians.description',
    duration: [2],
    totalSteps: 6,
    steps: [
      'your-children',
      'primary',
      'backup',
      'your-pets',
      'pet-guardians',
      'pet-care-fund',
    ],
  },
  {
    id: 'estate',
    key: 'estate',
    name: 'Estate',
    flagKey: 'ESTATE',
    path: WILL_MODULE_PATH_MAPPING.estate,
    displayName: 'modules.will.estate.displayName',
    description: 'modules.will.estate.description',
    duration: [4],
    totalSteps: 8,
    steps: [
      '',
      'beneficiaries',
      'distribution',
      'backup-beneficiaries',
      'backup-distribution',
    ],
  },
  {
    id: 'gifts',
    key: 'gifts',
    name: 'Gifts',
    flagKey: 'GIFTS',
    path: WILL_MODULE_PATH_MAPPING.gifts,
    displayName: 'modules.will.gifts.displayName',
    description: 'modules.will.gifts.description',
    duration: [1, 5],
    totalSteps: 2,
    steps: [''],
  },
  {
    id: 'assets',
    key: 'assets',
    name: 'Assets',
    flagKey: 'ASSETS',
    path: WILL_MODULE_PATH_MAPPING.assets,
    displayName: 'modules.will.assets.displayName',
    description: 'modules.will.assets.description',
    duration: [2, 3],
    totalSteps: 4,
    steps: ['', 'asset-notes', 'liabilities', 'liability-notes'],
  },
  {
    id: 'executors',
    key: 'executors',
    name: 'Executors',
    flagKey: 'EXECUTORS',
    path: WILL_MODULE_PATH_MAPPING.executors,
    displayName: 'modules.will.executors.displayName',
    description: 'modules.will.executors.description',
    duration: [4],
    totalSteps: 4,
    steps: ['', 'options', 'primary', 'backup'],
  },
  {
    id: 'funeral',
    key: 'funeral',
    name: 'Funeral',
    flagKey: 'FUNERAL',
    path: WILL_MODULE_PATH_MAPPING.funeral,
    displayName: 'modules.will.funeral.displayName',
    description: 'modules.will.funeral.description',
    duration: [2, 3],
    totalSteps: 3,
    steps: ['', 'location', 'other-requests'],
  },
  {
    id: 'review',
    key: 'review',
    name: 'Review',
    path: WILL_MODULE_PATH_MAPPING.review,
    displayName: 'modules.will.review.displayName',
    description: 'modules.will.review.description',
    duration: [4],
    totalSteps: 2,
    steps: [],
  },
];

const POA_MODULES = [
  {
    id: 'about-yourself',
    key: 'aboutYourself',
    name: 'About Yourself',
    path: POA_MODULE_PATH_MAPPING.aboutYourself,
    displayName: 'modules.poa.aboutYourself.displayName',
    description: 'modules.poa.aboutYourself.description',
    duration: [1, 2],
    totalSteps: 1,
    steps: [''],
  },
  {
    id: 'financial',
    key: 'financial',
    name: 'Financial',
    path: POA_MODULE_PATH_MAPPING.financial,
    displayName: 'modules.poa.financial.displayName',
    description: 'modules.poa.financial.description',
    duration: [2, 5],
    totalSteps: 2,
    steps: ['', 'primary', 'substitutes', 'spending', 'limitations'],
  },
  {
    id: 'medical',
    key: 'medical',
    name: 'Medical',
    path: POA_MODULE_PATH_MAPPING.medical,
    displayName: 'modules.poa.medical.displayName',
    description: 'modules.poa.medical.description',
    duration: [2, 5],
    totalSteps: 2,
    steps: ['', 'primary', 'substitutes', 'spending', 'limitations'],
  },
];

const WILL_TOTAL_STEPS = WILL_MODULES.reduce(
  (total, module) => total + module.totalSteps,
  0
);

const WILL_MODULES_FLAG_KEYS = Object.fromEntries(
  WILL_MODULES.map((module) => [module.key, module.flagKey])
);

const willMetaDefaults = {
  asset_note: null,
  charity_in_estate: true,
  charity_in_gifts: true,
  estate_backup_index: 0,
  estate_split: null,
  executors_option: null,
  funeral_location: null,
  funeral_note: null,
  funeral_skip: null,
  funeral_type: null,
  has_alt_name: null,
  has_assets: null,
  has_backup_executor: null,
  has_backup_guardian: null,
  has_children: null,
  has_gifts: null,
  has_liabilities: null,
  has_partner: null,
  has_pet_guardians: null,
  has_pets: null,
  has_primary_guardian: null,
  intent_to_sign: null,
  life_insurance_upsell: null,
  notify_charities: null,
  pet_care_fund: '',
  professional_executor_terms_agreed_at: null,
  show_welcome_modal: true,
};

const securityLink = {
  au: 'https://safewill.com/security',
  nz: 'https://safewill.com/nz/security',
};

const privacyLink = {
  au: 'https://safewill.com/privacy',
  nz: 'https://safewill.com/nz/privacy',
};

const WILL_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
  FLAGGED: 'FLAGGED',
  ARCHIVED: 'ARCHIVED',
};

const IDENTIFIER_TYPE = {
  EMAIL: 'email',
  DOB: 'dob',
  ADDRESS: 'address',
  PHONE: 'phone',
};

const RELATIONSHIP_TYPE = {
  PARTNER: 'PARTNER',
  CHILD: 'CHILD',
  STEPCHILD: 'STEPCHILD',
  SIBLING: 'SIBLING',
  PARENT: 'PARENT',
  EXTENDED_FAMILY: 'EXTENDED_FAMILY',
  FRIEND: 'FRIEND',
  FORMER_PARTNER: 'FORMER_PARTNER',
  PROFESSIONAL: 'PROFESSIONAL',
  UNKNOWN: 'UNKNOWN',
};

const COUNTRY = {
  AU: 'AU',
  NZ: 'NZ',
};

const AU_STATE = {
  ACT: 'ACT',
  NSW: 'NSW',
  NT: 'NT',
  QLD: 'QLD',
  SA: 'SA',
  TAS: 'TAS',
  VIC: 'VIC',
  WA: 'WA',
};

const AU_STATES = Object.values(AU_STATE);

const AU_STATE_NAME = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia',
};

const AU_STATE_OPTIONS = Object.entries(AU_STATE_NAME).map(([key, value]) => ({
  text: value,
  value: key,
}));

const PLAN_FLAG_STATUS = {
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
  IGNORED: 'IGNORED',
  ACTIONED: 'ACTIONED',
};

const PLAN_FLAG_ACTION = {
  RESOLVE: 'RESOLVE',
  IGNORE: 'IGNORE',
  ADD_TO_CART: 'ADD_TO_CART',
};

const PLAN_TYPE = {
  WILL: 'WILL',
};

const VAULT_ITEM_KEY = {
  SITUATION_FAMILY_CHILD: 'SITUATION_FAMILY_CHILD',
  SITUATION_FAMILY_PARTNER: 'SITUATION_FAMILY_PARTNER',
  SITUATION_FAMILY_OTHER: 'SITUATION_FAMILY_OTHER',
  DECISION_EXCLUSION_DEATH: 'DECISION_EXCLUSION_DEATH',
  SITUATION_FINANCIAL_ASSETS_SIZE: 'SITUATION_FINANCIAL_ASSETS_SIZE',
  SITUATION_FINANCIAL_ASSETS_LOCATION: 'SITUATION_FINANCIAL_ASSETS_LOCATION',
  SITUATION_FINANCIAL_ASSET_TYPE: 'SITUATION_FINANCIAL_ASSET_TYPE',
  DECISION_MAKER_GUARDIANSHIP_CHILD: 'DECISION_MAKER_GUARDIANSHIP_CHILD',
  DECISION_INSTRUCTION_DEATH: 'DECISION_INSTRUCTION_DEATH',
  DECISION_MAKER_DEATH: 'DECISION_MAKER_DEATH',
};

const VAULT_ITEM_TYPE = {
  HAS_CHILD_WITH_PREVIOUS_PARTNER: 'HAS_CHILD_WITH_PREVIOUS_PARTNER',
  HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS:
    'HAS_UPCOMING_CHANGE_OF_MARITAL_STATUS',
  ESTATE_BENEFICIARY: 'ESTATE_BENEFICIARY',
  HAS_FAMILY_MEMBER_WITH_DISABILITY: 'HAS_FAMILY_MEMBER_WITH_DISABILITY',
  HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE:
    'HAS_SIGNIFICANT_EXCLUDING_PRIMARY_RESIDENCE',
  HAS_INTERNATIONAL: 'HAS_INTERNATIONAL',
  IS_BUSINESS_OWNER: 'IS_BUSINESS_OWNER',
  IS_SELF_MANAGED_PENSION_FUND_OWNER: 'IS_SELF_MANAGED_PENSION_FUND_OWNER',
  HAS_FAMILY_TRUST_INTEREST: 'HAS_FAMILY_TRUST_INTEREST',
  ESTATE_BENEFICIARY_BACKUP: 'ESTATE_BENEFICIARY_BACKUP',
  ESTATE_BENEFICIARY_GIFT: 'ESTATE_BENEFICIARY_GIFT',
};

const TOOL_TYPE = {
  ONBOARDING_NEEDS_ASSESSMENT: 'ONBOARDING_NEEDS_ASSESSMENT',
  WILL_HELP_ASSESSMENT: 'WILL_HELP_ASSESSMENT',
};
const TOOL_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
};

const MODULE_TO_HELP_ASSESSMENT_VAULT_ITEMS = {
  FAMILY: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_MAKER_GUARDIANSHIP_CHILD,
    },
  ],
  ESTATE: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
      TYPE: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY,
    },
    {
      KEY: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
      TYPE: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY_BACKUP,
    },
  ],
  GIFTS: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_INSTRUCTION_DEATH,
      TYPE: VAULT_ITEM_TYPE.ESTATE_BENEFICIARY_GIFT,
    },
  ],
  EXECUTORS: [
    {
      KEY: VAULT_ITEM_KEY.DECISION_MAKER_DEATH,
    },
  ],
};

const ONBOARDING_STEP = {
  NAME: 'name',
  DOB: 'dob',
  COUNTRY: 'country',
  FAMILY: 'family',
  ASSETS: 'assets',
  FINISH: 'finish',
  INTRODUCTION: 'introduction',
};

const DISCOUNT_OPTIONS = ['PERCENTAGE', 'FIXED'];

const GET_STARTED_VARIATION = {
  CONTROL: 'control',
  VARIATION_1: 'variation1',
  VARIATION_2A: 'variation2a',
  VARIATION_2B: 'variation2b',
  VARIATION_3A: 'variation3a',
  VARIATION_3B: 'variation3b',
};

const WILL_TIERS_SLICE_TWO_ONE_VARIATION = {
  CONTROL: 'control',
  VARIATION_ONE: 'variation-one',
  VARIATION_TWO: 'variation-two',
};

const DEMO_PAGES = {
  INTRODUCTION: {
    url: '/get-started/demo/introduction',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=59-4117&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=59%3A4117&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  ABOUT_YOURSELF: {
    url: '/get-started/demo/about-yourself',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?kind=proto&node-id=4-3746&page-id=1%3A48&scaling=fixed&show-proto-sidebar=0&starting-point-node-id=4%3A3746&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  GUARDIANS: {
    url: '/get-started/demo/guardians',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=6-5105&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=6%3A5105&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  ESTATE: {
    url: '/get-started/demo/estate',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=7-6562&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=7%3A6562&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  GIFTS: {
    url: '/get-started/demo/gifts',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=32-4820&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=32%3A4820&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  ASSETS: {
    url: '/get-started/demo/assets',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=32-5484&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=32%3A5484&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  EXECUTORS: {
    url: '/get-started/demo/executors',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=32-5683&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=32%3A5683&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  FUNERAL: {
    url: '/get-started/demo/funeral',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=32-5787&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=32%3A5787&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
  REVIEW: {
    url: '/get-started/demo/review',
    figmaUrl:
      'https://embed.figma.com/proto/mKTS8wKHKQ79Drdcc95GRa/Estate-planning-10.2---Continue-without-signing-up-test?node-id=32-6176&node-type=frame&scaling=min-zoom&content-scaling=fixed&page-id=1%3A48&starting-point-node-id=32%3A6176&show-proto-sidebar=0&hotspot-hints=0&hide-ui=1&embed-host=share&content-scaling=responsive',
  },
};

const AFFILIATE_BRANDING = {
  getStartedTitleOne: null,
  getStartedDescriptionOne: null,
  getStartedTitleTwo: null,
  getStartedDescriptionTwo: null,
  getStartedTitleThree: null,
  getStartedDescriptionThree: null,
  getStartedAffiliateLogoShown: true,
  appHeaderAffiliateLogoShown: false,
  logo: null,
  slug: 'sw',
  branding: {
    theme: '#00D9BF',
    darker: '#00A894',
    lighter: '#D2F9E2',
    landingImage: null,
  },
};

const AFFILIATE_FEATURES = {
  dashboardEnabled: true,
  willEnabled: true,
  powerOfAttorneyEnabled: true,
  endOfLifeEnabled: true,
  giftEnabled: true,
  onboardingNeedsAssessmentEnabled: true,
  willTierTwoEnabled: true,
  affiliateUserIdEnabled: false,
  couponCodeEnabled: false,
};

const countryCallingCodesArray = [
  ['🇦🇺', '+61', ['Australia']],
  ['🇳🇿', '+64', ['New Zealand']],
  ['🇺🇸 🇨🇦', '+1', ['Canada', 'USA']],
  ['🇷🇺 🇰🇿', '+7', ['Russia', 'Kazakhstan']],
  ['🇪🇬', '+20', ['Egypt']],
  ['🇿🇦', '+27', ['South Africa']],
  ['🇬🇷', '+30', ['Greece']],
  ['🇳🇱', '+31', ['Netherlands']],
  ['🇧🇪', '+32', ['Belgium']],
  ['🇫🇷', '+33', ['France']],
  ['🇪🇸', '+34', ['Spain']],
  ['🇭🇺', '+36', ['Hungary']],
  ['🇮🇹 🇻🇦', '+39', ['Italy', 'Holy See']],
  ['🇷🇴', '+40', ['Romania']],
  ['🇨🇭', '+41', ['Switzerland']],
  ['🇦🇹', '+43', ['Austria']],
  ['🇬🇧', '+44', ['United Kingdom']],
  ['🇩🇰', '+45', ['Denmark']],
  ['🇸🇪', '+46', ['Sweden']],
  ['🇳🇴', '+47', ['Norway', 'Bouvet Island']],
  ['🇵🇱', '+48', ['Poland']],
  ['🇩🇪', '+49', ['Germany']],
  ['🇵🇪', '+51', ['Peru']],
  ['🇲🇽', '+52', ['Mexico']],
  ['🇨🇺', '+53', ['Cuba']],
  ['🇦🇷', '+54', ['Argentina']],
  ['🇧🇷', '+55', ['Brazil']],
  ['🇨🇱', '+56', ['Chile']],
  ['🇨🇴', '+57', ['Colombia']],
  ['🇻🇪', '+58', ['Venezuela']],
  ['🇲🇾', '+60', ['Malaysia']],
  ['🇮🇩', '+62', ['Indonesia']],
  ['🇵🇭', '+63', ['Philippines']],
  ['🇸🇬', '+65', ['Singapore']],
  ['🇹🇭', '+66', ['Thailand']],
  ['🇯🇵', '+81', ['Japan']],
  ['🇰🇷', '+82', ['South Korea']],
  ['🇻🇳', '+84', ['Vietnam']],
  ['🇨🇳', '+86', ['China']],
  ['🇹🇷', '+90', ['Turkey']],
  ['🇮🇳', '+91', ['India']],
  ['🇵🇰', '+92', ['Pakistan']],
  ['🇦🇫', '+93', ['Afghanistan']],
  ['🇱🇰', '+94', ['Sri Lanka']],
  ['🇲🇲', '+95', ['Myanmar']],
  ['🇮🇷', '+98', ['Iran']],
  ['🇸🇸', '+211', ['South Sudan']],
  ['🇲🇦 🇪🇭', '+212', ['Morocco', 'Western Sahara']],
  ['🇩🇿', '+213', ['Algeria']],
  ['🇹🇳', '+216', ['Tunisia']],
  ['🇱🇾', '+218', ['Libya']],
  ['🇬🇲', '+220', ['Gambia']],
  ['🇸🇳', '+221', ['Senegal']],
  ['🇲🇷', '+222', ['Mauritania']],
  ['🇲🇱', '+223', ['Mali']],
  ['🇬🇳', '+224', ['Guinea']],
  ['🇨🇮', '+225	', [`Côte d'Ivoire`]],
  ['🇧🇫', '+226', ['Burkina Faso']],
  ['🇳🇪', '+227', ['Niger']],
  ['🇹🇬', '+228', ['Togo']],
  ['🇧🇯', '+229', ['Benin']],
  ['🇲🇺', '+230', ['Mauritius']],
  ['🇱🇷', '+231', ['Liberia']],
  ['🇸🇱', '+232', ['Sierra Leone']],
  ['🇬🇭', '+233', ['Ghana']],
  ['🇳🇬', '+234', ['Nigeria']],
  ['🇹🇩', '+235', ['Chad']],
  ['🇨🇫', '+236', ['Central African Republic']],
  ['🇨🇲', '+237', ['Cameroon']],
  ['🇨🇻', '+238', ['Cabo Verde']],
  ['🇸🇹', '+239', ['Sao Tome & Principe']],
  ['🇬🇶', '+240', ['Equatorial Guinea']],
  ['🇬🇦', '+241', ['Gabon']],
  ['🇨🇬', '+242', ['Republic of the Congo']],

  ['🇨🇩', '+243', ['Democratic Republic of the Congo']],
  ['🇦🇴', '+244', ['Angola']],
  ['🇬🇼', '+245', ['Guinea-Bissau']],

  ['🇮🇴', '+246', ['British Indian Ocean Territories']],
  ['🇸🇨', '+248', ['Seychelles']],
  ['🇸🇩', '+249', ['Sudan']],
  ['🇷🇼', '+250', ['Rwanda']],
  ['🇪🇹', '+251', ['Ethiopia']],
  ['🇸🇴', '+252', ['Somalia']],
  ['🇩🇯', '+253', ['Djibouti']],
  ['🇰🇪', '+254', ['Kenya']],
  ['🇹🇿', '+255', ['United Republic of Tanzania']],
  ['🇺🇬', '+256', ['Uganda']],
  ['🇧🇮', '+257', ['Burundi']],
  ['🇲🇿', '+258', ['Mozambique']],
  ['🇿🇲', '+260', ['Zambia']],
  ['🇲🇬', '+261', ['Madagascar']],
  ['🇾🇹 🇷🇪', '+262', ['Mayotte', 'Réunion']],
  ['🇿🇼', '+263', ['Zimbabwe']],
  ['🇳🇦', '+264', ['Namibia']],
  ['🇲🇼', '+265', ['Malawi']],
  ['🇱🇸', '+266', ['Lesotho']],
  ['🇧🇼', '+267', ['Botswana']],
  ['🇸🇿', '+268', ['Eswatini']],
  ['🇰🇲', '+269', ['Comoros']],

  ['🇸🇭', '+290', ['Saint Helena, Ascension & Tristan da Cunha']],
  ['🇪🇷', '+291', ['Eritrea']],
  ['🇦🇼', '+297', ['Aruba']],
  ['🇫🇴', '+298', ['Faroe Islands']],
  ['🇬🇱', '+299', ['Greenland']],
  ['🇬🇮', '+350', ['Gibraltar']],
  ['🇵🇹', '+351', ['Portugal']],
  ['🇱🇺', '+352', ['Luxembourg']],
  ['🇮🇪', '+353', ['Ireland']],
  ['🇮🇸', '+354', ['Iceland']],
  ['🇦🇱', '+355', ['Albania']],
  ['🇲🇹', '+356', ['Malta']],
  ['🇨🇾', '+357', ['Cyprus']],
  ['🇫🇮 🇦🇽', '+358', ['Finland', 'Åland Islands']],
  ['🇧🇬', '+359', ['Bulgaria']],
  ['🇱🇹', '+370', ['Lithuania']],
  ['🇱🇻', '+371', ['Latvia']],
  ['🇪🇪', '+372', ['Estonia']],
  ['🇲🇩', '+373', ['Republic of Moldova']],
  ['🇦🇲', '+374', ['Armenia']],
  ['🇧🇾', '+375', ['Belarus']],
  ['🇦🇩', '+376', ['Andorra']],
  ['🇲🇨', '+377', ['Monaco']],
  ['🇸🇲', '+378', ['Republic of San Marino']],
  ['🇺🇦', '+380', ['Ukraine']],
  ['🇷🇸', '+381', ['Serbia']],
  ['🇲🇪', '+382', ['Montenegro']],
  ['🇽🇰', '+383', ['Republic of Kosovo']],
  ['🇭🇷', '+385', ['Croatia']],
  ['🇸🇮', '+386', ['Slovenia']],
  ['🇧🇦', '+387', ['Bosnia & Herzegovina']],
  ['🇲🇰', '+389', ['North Macedonia']],
  ['🇨🇿', '+420', ['Czechia']],
  ['🇸🇰', '+421', ['Slovakia']],
  ['🇱🇮', '+423', ['Liechtenstein']],

  [
    '🇫🇰 🇬🇸',
    '+500',
    ['Falkland Islands', 'South Georgia & the South Sandwich Islands'],
  ],
  ['🇧🇿', '+501', ['Belize']],
  ['🇬🇹', '+502', ['Guatemala']],
  ['🇸🇻', '+503', ['El Salvador']],
  ['🇭🇳', '+504', ['Honduras']],
  ['🇳🇮', '+505', ['Nicaragua']],
  ['🇨🇷', '+506', ['Costa Rica']],
  ['🇵🇦', '+507', ['Panama']],
  ['🇵🇲', '+508', ['Saint Pierre & Miquelon']],
  ['🇭🇹', '+509', ['Haiti']],

  ['🇬🇵 🇧🇱 🇲🇫', '+590', ['Guadeloupe', 'Saint Martin', 'Saint Barthélemy']],
  ['🇧🇴', '+591', ['Bolivia']],
  ['🇬🇾', '+592', ['Guyana']],
  ['🇪🇨', '+593', ['Ecuador']],
  ['🇬🇫', '+594', ['French Guiana']],
  ['🇵🇾', '+595', ['Paraguay']],
  ['🇲🇶', '+596', ['Martinique']],
  ['🇸🇷', '+597', ['Suriname']],
  ['🇺🇾', '+598', ['Uruguay']],

  ['🇨🇼 🇧🇶', '+599', ['Curaçao', 'Netherlands Antilles']],
  ['🇹🇱', '+670', ['Timor-Leste']],

  [
    '🇦🇶 🇹🇫 🇭🇲 🇳🇫',
    '+672',
    [
      'Antarctica',
      'Norfolk Island',
      'Territory of Heard Island & McDonald Islands',
      'French Southern & Antarctic Lands',
    ],
  ],
  ['🇧🇳', '+673', ['Brunei Darussalam']],
  ['🇳🇷', '+674', ['Nauru']],
  ['🇵🇬', '+675', ['Papua New Guinea']],
  ['🇹🇴', '+676', ['Tonga']],
  ['🇸🇧', '+677', ['Solomon Islands']],
  ['🇻🇺', '+678', ['Vanuatu']],
  ['🇫🇯', '+679', ['Fiji']],
  ['🇵🇼', '+680', ['Palau']],
  ['🇼🇫', '+681', ['Wallis & Futuna']],
  ['🇨🇰', '+682', ['Cook Islands']],
  ['🇳🇺', '+683', ['Niue']],
  ['🇼🇸', '+685', ['Samoa']],
  ['🇰🇮', '+686', ['Kiribati']],
  ['🇳🇨', '+687', ['New Caledonia']],
  ['🇹🇻', '+688', ['Tuvalu']],
  ['🇵🇫', '+689', ['French Polynesia']],
  ['🇹🇰', '+690', ['Tokelau']],
  ['🇫🇲', '+691', ['Micronesia']],

  ['🇲🇭', '+692', ['Republic of the Marshall Islands']],
  ['🇩🇲', '+767', ['Dominica']],
  ['🇰🇵', '+850', ['North Korea']],
  ['🇭🇰', '+852', ['Hong Kong']],
  ['🇲🇴', '+853', ['Macao']],
  ['🇰🇭', '+855', ['Cambodia']],

  ['🇱🇦', '+856	', [`Lao People's Democratic Republic`]],
  ['🇹🇹', '+868', ['Trinidad & Tobago']],
  ['🇯🇲', '+876', ['Jamaica']],
  ['🇧🇩', '+880', ['Bangladesh']],
  ['🇹🇼', '+886', ['Taiwan, Province of China']],
  ['🇲🇻', '+960', ['Maldives']],
  ['🇱🇧', '+961', ['Lebanon']],
  ['🇯🇴', '+962', ['Jordan']],
  ['🇸🇾', '+963', ['Syrian Arab Republic']],
  ['🇮🇶', '+964', ['Iraq']],
  ['🇰🇼', '+965', ['Kuwait']],
  ['🇸🇦', '+966', ['Saudi Arabia']],
  ['🇾🇪', '+967', ['Yemen']],
  ['🇴🇲', '+968', ['Oman']],
  ['🇵🇸', '+970', ['Palestine']],
  ['🇦🇪', '+971', ['United Arab Emirates']],
  ['🇮🇱', '+972', ['Israel']],
  ['🇧🇭', '+973', ['Bahrain']],
  ['🇶🇦', '+974', ['Qatar']],
  ['🇧🇹', '+975', ['Bhutan']],
  ['🇲🇳', '+976', ['Mongolia']],
  ['🇳🇵', '+977', ['Nepal']],
  ['🇹🇯', '+992', ['Tajikistan']],
  ['🇹🇲', '+993', ['Turkmenistan']],
  ['🇦🇿', '+994', ['Azerbaijan']],
  ['🇬🇪', '+995', ['Georgia']],
  ['🇰🇬', '+996', ['Kyrgyzstan']],
  ['🇺🇿', '+998', ['Uzbekistan']],
  ['🇧🇸', '+1242', ['Bahamas']],
  ['🇧🇧', '+1246', ['Barbados']],
  ['🇦🇮', '+1264', ['Anguilla']],
  ['🇦🇬', '+1268', ['Antigua & Barbuda']],
  ['🇻🇬', '+1284', ['Virgin Islands (British)']],
  ['🇻🇮', '+1340', ['Virgin Islands (U.S.)']],
  ['🇰🇾', '+1345', ['Cayman Islands']],
  ['🇧🇲', '+1441', ['Bermuda']],
  ['🇬🇩', '+1473', ['Grenada']],
  ['🇹🇨', '+1649', ['Turks & Caicos Islands']],
  ['🇲🇸', '+1664', ['Montserrat']],
  ['🇲🇵', '+1670', ['Northern Mariana Islands']],
  ['🇦🇸', '+1684', ['American Samoa']],
  ['🇸🇽', '+1721', ['Sint Maarten']],
  ['🇱🇨', '+1758', ['Saint Lucia']],
  ['🇻🇨', '+1784', ['Saint Vincent & the Grenadines']],
  ['🇰🇳', '+1869', ['Saint Kitts & Nevis']],
  ['🇸🇯', '+4779', ['Svalbard & Jan Mayen']],
  ['🇧🇶', '+5997', ['Bonaire, Sint Eustatius & Saba']],
  ['🇨🇨', '+61891', ['Cocos Islands']],
];

const countryCallingCodes = countryCallingCodesArray.map(
  ([flags, code, countries]) => ({
    flags,
    code,
    countries,
  })
);

const WILL_BASE_PRICE = 16000;

export {
  willMetaDefaults,
  WILL_MODULE_PATH_MAPPING,
  POA_MODULE_PATH_MAPPING,
  WILL_MODULES,
  WILL_TOTAL_STEPS,
  WILL_MODULES_FLAG_KEYS,
  POA_MODULES,
  securityLink,
  privacyLink,
  IDENTIFIER_TYPE,
  RELATIONSHIP_TYPE,
  COUNTRY,
  AU_STATE,
  AU_STATES,
  AU_STATE_OPTIONS,
  PLAN_FLAG_STATUS,
  PLAN_FLAG_ACTION,
  PLAN_TYPE,
  countryCallingCodes,
  WILL_BASE_PRICE,
  VAULT_ITEM_KEY,
  VAULT_ITEM_TYPE,
  WILL_STATUS,
  TOOL_TYPE,
  TOOL_STATUS,
  ONBOARDING_STEP,
  MODULE_TO_HELP_ASSESSMENT_VAULT_ITEMS,
  DISCOUNT_OPTIONS,
  GET_STARTED_VARIATION,
  WILL_TIERS_SLICE_TWO_ONE_VARIATION,
  DEMO_PAGES,
  AFFILIATE_BRANDING,
  AFFILIATE_FEATURES,
};
/* c8 ignore stop */
