import cookies from 'js-cookie';
import GET_PARTNERSHIP_BRANDING from '@/graphql/queries/GetPartnershipBranding';
import GET_PARTNERSHIP_AFFILIATE_FEATURES from '@/graphql/queries/GetPartnershipAffiliateFeatures';
import { hexToRgb, saveAnonymousProfile } from '@/utilities';
import { AFFILIATE_BRANDING, AFFILIATE_FEATURES } from '@/utilities/constants';

export const state = () => ({
  brandingData: {
    ...AFFILIATE_BRANDING,
  },
  features: {
    ...AFFILIATE_FEATURES,
  },
});

export const getters = {
  isAffiliate: (_state, getters) => getters.slug !== 'sw',
  slug: (state) => state.brandingData.slug || 'sw',
  logo: (state) => state.brandingData.logo,
  branding: (state) => state.brandingData.branding,
  features: (state) => state.features,
  getStartedBranding: (state) => ({
    titleOne: state.brandingData.getStartedTitleOne,
    descriptionOne: state.brandingData.getStartedDescriptionOne,
    titleTwo: state.brandingData.getStartedTitleTwo,
    descriptionTwo: state.brandingData.getStartedDescriptionTwo,
    titleThree: state.brandingData.getStartedTitleThree,
    descriptionThree: state.brandingData.getStartedDescriptionThree,
    affiliateLogoVisible: state.brandingData.getStartedAffiliateLogoShown,
  }),
  appBranding: (state) => ({
    affiliateLogoVisible: state.brandingData.appHeaderAffiliateLogoShown,
  }),
};

export const mutations = {
  SET_FEATURES(state, value) {
    state.features = { ...value };
  },
  SET_AFFILIATE_BRANDING(state, value) {
    state.brandingData = { ...value };
  },
};

export const actions = {
  async getAffiliateBranding(
    { commit, dispatch },
    { affiliateSlug, affiliateId = null }
  ) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_PARTNERSHIP_BRANDING,
      variables: affiliateId ? { id: affiliateId } : { slug: affiliateSlug },
    });

    if (data.getPartnershipBranding) {
      const { slug } = data.getPartnershipBranding;
      commit('SET_AFFILIATE_BRANDING', data.getPartnershipBranding);

      saveAnonymousProfile({ affiliateSlug: slug });
      cookies.set('affiliate_slug', slug);
    } else {
      commit('SET_AFFILIATE_BRANDING', AFFILIATE_BRANDING);
    }

    dispatch('setBrandingTheme');
  },
  setBrandingTheme({ getters }) {
    const root = document.documentElement;
    const { theme, darker, lighter } = getters.branding;

    if (theme) {
      root.style.setProperty('--theme', hexToRgb(theme));
    }
    if (darker) {
      root.style.setProperty('--darker', hexToRgb(darker));
    }
    if (lighter) {
      root.style.setProperty('--lighter', hexToRgb(lighter));
    }

    if (getters.isAffiliate) {
      root.classList.add('--affiliate');
    } else {
      root.classList.remove('--affiliate');
    }
  },
  async getAffiliateFeatures({ commit }, affiliateSlug) {
    const { data: affiliateData } =
      await this.app.apolloProvider.defaultClient.query({
        query: GET_PARTNERSHIP_AFFILIATE_FEATURES,
        variables: {
          slug: affiliateSlug,
        },
      });

    if (affiliateData.getPartnershipAffiliateFeatures) {
      commit('SET_FEATURES', affiliateData.getPartnershipAffiliateFeatures);
    }
  },
  resetAffiliate({ commit }) {
    commit('SET_AFFILIATE_BRANDING', AFFILIATE_BRANDING);
    commit('SET_FEATURES', AFFILIATE_FEATURES);
    cookies.remove('affiliate_slug');
  },
};
