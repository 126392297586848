import GET_PRODUCT_PRICES_QUERY from '@/graphql/queries/getProductPrices';

const PRODUCT_CODES = [
  'WILL',
  'WILL_TIER_TWO',
  'PARTNER_WILL',
  'POA',
  'PARTNER_POA',
  'RECENT_UNLOCK',
  'UNLOCK',
  'SUBSCRIPTION',
  'SUBSCRIPTION_RENEWAL',
  'LEGAL_ADVICE',
  'LEGAL_ADVICE_BOOKING',
  'WILL_GIFT',
  'POA_GIFT',
];

export const state = () => ({
  productPrices: {},
  productSlugs: {},
});

export const getters = {
  legalAdvicePrice: (state) => state.productPrices.LEGAL_ADVICE,
  legalAdviceSlug: (state) => state.productSlugs.LEGAL_ADVICE,
  legalAdviceBookingPrice: (state) => state.productPrices.LEGAL_ADVICE_BOOKING,
  legalAdviceBookingSlug: (state) => state.productSlugs.LEGAL_ADVICE_BOOKING,
  partnerWillPrice: (state) => state.productPrices.PARTNER_WILL,
  partnerWillSlug: (state) => state.productSlugs.PARTNER_WILL,
  partnerPoaPrice: (state) => state.productPrices.PARTNER_POA,
  partnerPoaSlug: (state) => state.productSlugs.PARTNER_POA,
  poaPrice: (state) => state.productPrices.POA,
  poaSlug: (state) => state.productSlugs.POA,
  poaGiftPrice: (state) => state.productPrices.POA_GIFT,
  poaGiftSlug: (state) => state.productSlugs.POA_GIFT,
  recentUnlockPrice: (state) => state.productPrices.RECENT_UNLOCK,
  recentUnlockSlug: (state) => state.productSlugs.RECENT_UNLOCK,
  subscriptionPrice: (state) => state.productPrices.SUBSCRIPTION,
  subscriptionSlug: (state) => state.productSlugs.SUBSCRIPTION,
  subscriptionRenewalPrice: (state) => state.productPrices.SUBSCRIPTION_RENEWAL,
  subscriptionRenewalSlug: (state) => state.productSlugs.SUBSCRIPTION_RENEWAL,
  unlockPrice: (state) => state.productPrices.UNLOCK,
  unlockSlug: (state) => state.productSlugs.UNLOCK,
  willPrice: (state) => state.productPrices.WILL,
  willTierTwoPrice: (state) => state.productPrices.WILL_TIER_TWO,
  willSlug: (state) => state.productSlugs.WILL,
  willGiftPrice: (state) => state.productPrices.WILL_GIFT,
  willGiftSlug: (state) => state.productSlugs.WILL_GIFT,
  productPrices: (state) => state.productPrices,
  productSlugs: (state) => state.productSlugs,
  priceOf: (state) => (code) => state.productPrices[code],
  slugOf: (state) => (code) => state.productSlugs[code],
};

export const mutations = {
  SET_PRODUCT_PRICES(state, payload) {
    state.productPrices = payload;
  },
  SET_PRODUCT_SLUGS(state, payload) {
    state.productSlugs = payload;
  },
};

export const actions = {
  async getProductPrices(
    { rootState, rootGetters, commit },
    productCodes = PRODUCT_CODES
  ) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_PRODUCT_PRICES_QUERY,
      variables: {
        userId: rootState.userId,
        productCodes: productCodes || PRODUCT_CODES,
        discountCode:
          rootGetters['user/cart/discountCode'] ||
          rootGetters['user/coupon/couponLatestReferral'],
      },
    });
    const { productPrices, productSlugs } = processProductData(
      data.getProductPrices
    );
    commit('SET_PRODUCT_PRICES', productPrices);
    commit('SET_PRODUCT_SLUGS', productSlugs);
  },
  setProductPrices({ commit }, rawProductPrices) {
    const { productPrices, productSlugs } =
      processProductData(rawProductPrices);
    commit('SET_PRODUCT_PRICES', productPrices);
    commit('SET_PRODUCT_SLUGS', productSlugs);
  },
};

function processProductData(rawProductPrices) {
  const productPrices = {};
  const productSlugs = {};
  rawProductPrices.forEach((item) => {
    productPrices[item.productCode] = item.discountedPrice;
    productSlugs[item.productCode] = item.productPriceSlug;
  });
  return { productPrices, productSlugs };
}
