import gql from 'graphql-tag';

export default gql`
  query validateDeleteDirectoryPerson($id: ID!) {
    validateDeleteDirectoryPerson(id: $id) {
      isValid
      personRoles
    }
  }
`;
