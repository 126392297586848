import cloneDeep from 'lodash/cloneDeep';

import GET_ORDERS from '@/graphql/queries/GetOrders';
import GET_SUBSCRIPTION_QUERY from '@/graphql/queries/GetSubscription';

import { pollUntilTruthy } from '@/utilities';

export const state = () => ({
  orders: [],
  isProcessingOrder: false,
});

export const getters = {
  orders: (state) => {
    return cloneDeep(state.orders).sort(
      (a, b) => Number(b.createdAt) - Number(a.createdAt)
    );
  },
  latestOrder: (state) => {
    return state.orders[0];
  },
  purchasedProductCodes: (state) => {
    return cloneDeep(state.orders)
      .filter(({ status }) => status === 'SUCCESSFUL')
      .flatMap(({ orderItems }) => orderItems.map(({ product }) => product));
  },
  latestPurchasedProductCodes: (_state, getters) => {
    return getters.latestOrder?.orderItems.map(({ product }) => product) ?? [];
  },
  isProcessingOrder: (state) => {
    return state.isProcessingOrder;
  },
  isProductPurchased: (_state, getters) => (code) => {
    return getters.purchasedProductCodes.includes(code);
  },
  isLatestProductPurchased: (_state, getters) => (code) => {
    return getters.latestPurchasedProductCodes.includes(code);
  },
};

export const mutations = {
  SET_ORDERS(state, data) {
    state.orders = data;
  },
  SET_IS_PROCESSING_ORDER(state, data) {
    state.isProcessingOrder = data;
  },
};

export const actions = {
  async getOrders({ rootGetters, commit }) {
    const userId = rootGetters.userId;
    if (!userId) {
      return;
    }
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_ORDERS,
      variables: {
        userId,
      },
    });
    commit('SET_ORDERS', data?.getOrders ?? []);
  },
  setIsProcessingOrder({ commit }, isProcessingOrder) {
    commit('SET_IS_PROCESSING_ORDER', isProcessingOrder);
  },
  checkPurchasedItems({ dispatch, rootGetters, getters }, purchasedItems) {
    const userId = rootGetters.userId;
    return pollUntilTruthy(async () => {
      await dispatch('getOrders');
      const latestOrder = getters.latestOrder;
      if (latestOrder?.status !== 'SUCCESSFUL') {
        return;
      }
      const products = latestOrder?.orderItems.map((item) => item.product);
      if (
        products.some((code) =>
          ['SUBSCRIPTION', 'UNLOCK', 'RECENT_UNLOCK'].includes(code)
        )
      ) {
        await pollUntilTruthy(async () => {
          const { data } = await this.app.apolloProvider.defaultClient.query({
            fetchPolicy: 'no-cache',
            query: GET_SUBSCRIPTION_QUERY,
            variables: {
              userId,
            },
          });
          return data?.getSubscription?.expiresAt > Date.now();
        });
      }
      return purchasedItems.every((purchasedItem) =>
        products.includes(purchasedItem)
      );
    });
  },
};
