import { mapActions, mapGetters } from 'vuex';

import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import UPDATE_WILL_MUTATION from '@/graphql/mutations/UpdateWill';
import UPDATE_WILL_ESTATE_SPLIT from '@/graphql/mutations/UpdateWillIsPrimaryEstateSplitEvenly';
import { error } from '@/mixins/apollo';

import { metaArrayToObject, objectToMetaArray } from '@/utilities';
import { willMetaDefaults } from '@/utilities/constants';

export default {
  name: 'MixinsApolloWill',

  apollo: {
    will: {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
      update: (data) => data?.getWill?.will,
      result({ data }) {
        if (data?.getWill?.will) {
          this.setWillData(data.getWill.will);
          this.setWillStatus(data.getWill.will.status);
          const invite = data.getWill.will.invite;
          if (invite) {
            if (invite && invite.type === 'PARTNER') {
              this.setInvitedByPartner(true);
            }
          }
        }
      },
      skip() {
        return !this.token || !this.willId;
      },
      fetchPolicy: 'no-cache',
      error,
    },
    willMeta: {
      query: GET_WILL_QUERY,
      variables() {
        return {
          id: this.willId,
        };
      },
      update: (data) =>
        data.getWill &&
        metaArrayToObject(data.getWill.will.meta, willMetaDefaults),
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      UPDATE_WILL_MUTATION,
      UPDATE_WILL_ESTATE_SPLIT,
      will: null,
      willMeta: {},
    };
  },
  computed: {
    ...mapGetters(['token', 'willId']),
    UPDATE_WILL_VARS() {
      return {
        id: this.willId,
        meta: this.willMeta ? objectToMetaArray(this.willMeta) : null,
      };
    },
  },
  methods: {
    ...mapActions(['setWillStatus']),
    ...mapActions('checkout', ['setInvitedByPartner']),
    ...mapActions('will', {
      setWillData: 'setData',
    }),
    async updateWillMeta() {
      await this.$apollo.mutate({
        mutation: this.UPDATE_WILL_MUTATION,
        variables: this.UPDATE_WILL_VARS,
      });
    },
    async updateWillIsPrimaryEstateSplitEvenly(isPrimaryEstateSplitEvenly) {
      await this.$apollo.mutate({
        mutation: this.UPDATE_WILL_ESTATE_SPLIT,
        variables: {
          id: this.willId,
          isPrimaryEstateSplitEvenly,
        },
      });
    },
    async refetchWill() {
      await this.$apollo.queries.will.refetch();
      await this.$apollo.queries.willMeta.refetch();
    },
  },
};
