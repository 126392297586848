import cloneDeep from 'lodash/cloneDeep';

import GET_PLAN_FLAGS_BY_PLAN_ID from '@/graphql/queries/GetPlanFlagsByPlanId';
import CREATE_PLAN_FLAG from '@/graphql/mutations/CreatePlanFlag';
import GET_FLAGS_BY_PLAN_TYPE_AND_USER_ID from '@/graphql/queries/GetFlagsByPlanTypeAndUserId';
import DELETE_PLAN_FLAG from '@/graphql/mutations/DeletePlanFlag';
import ACTION_PLAN_FLAG from '@/graphql/mutations/ActionPlanFlag';

import { PLAN_FLAG_STATUS } from '@/utilities/constants';
import { recursiveRemoveKey } from '@/utilities';

export const state = () => ({
  flags: [],
  planFlags: [],
});

export const getters = {
  flags: (state) => {
    return state.flags;
  },
  planFlags: (state) => {
    return state.planFlags;
  },
  hasActivePlanFlags: (state) => {
    return state.planFlags.some(
      (flag) => flag.status === PLAN_FLAG_STATUS.ACTIVE
    );
  },
  activePlanFlagsCount: (state) => {
    return cloneDeep(state.planFlags).filter(
      (flag) => flag.status === PLAN_FLAG_STATUS.ACTIVE
    ).length;
  },
  isPlanModuleFlagged: (state) => (module) => {
    return state.planFlags.some(
      (flag) =>
        flag.status === PLAN_FLAG_STATUS.ACTIVE &&
        flag.planSegment.module === module
    );
  },
};

export const mutations = {
  SET_FLAGS: (state, data) => {
    state.flags = data;
  },
  SET_PLAN_FLAGS: (state, data) => {
    state.planFlags = data;
  },
};

export const actions = {
  async getFlags({ commit }, { planType, userId }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_FLAGS_BY_PLAN_TYPE_AND_USER_ID,
      fetchPolicy: 'no-cache',
      variables: {
        planType,
        userId,
      },
    });
    const flags = data.getFlagsByPlanTypeAndUserId.map((flag) => {
      return {
        ...flag,
        internalName: this.app.i18n.t(`flags.${flag.slug}.internalName`),
      };
    });
    recursiveRemoveKey(flags, '__typename');
    commit('SET_FLAGS', flags);
  },
  async getPlanFlags({ commit }, { planId }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      query: GET_PLAN_FLAGS_BY_PLAN_ID,
      fetchPolicy: 'no-cache',
      variables: {
        planId,
      },
    });
    const planFlags = data.getPlanFlagsByPlanId.map((planFlag) => {
      return {
        ...planFlag,
        title: this.app.i18n.t(`flags.${planFlag.flag.slug}.title`),
        description: this.app.i18n.t(`flags.${planFlag.flag.slug}.description`),
        flag: {
          ...planFlag.flag,
          internalName: this.app.i18n.t(
            `flags.${planFlag.flag.slug}.internalName`
          ),
        },
      };
    });
    recursiveRemoveKey(planFlags, '__typename');
    commit('SET_PLAN_FLAGS', planFlags);
  },
  async createPlanFlag({ state, commit }, { flagId, planId, segment }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: CREATE_PLAN_FLAG,
      variables: {
        input: {
          flagId,
          planId,
          planSegment: segment,
        },
      },
    });
    const newPlanFlag = data.createPlanFlag;
    recursiveRemoveKey(newPlanFlag, '__typename');
    newPlanFlag.title = this.app.i18n.t(`flags.${newPlanFlag.flag.slug}.title`);
    newPlanFlag.description = this.app.i18n.t(
      `flags.${newPlanFlag.flag.slug}.description`
    );
    newPlanFlag.flag = {
      ...newPlanFlag.flag,
      internalName: this.app.i18n.t(
        `flags.${newPlanFlag.flag.slug}.internalName`
      ),
    };
    const newPlanFlags = [...state.planFlags, newPlanFlag];
    commit('SET_PLAN_FLAGS', newPlanFlags);
  },
  async deletePlanFlag({ state, commit }, id) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: DELETE_PLAN_FLAG,
      variables: {
        id,
      },
    });

    if (data.deletePlanFlag) {
      const newFlags = state.planFlags.filter((flag) => flag.id !== id);
      commit('SET_PLAN_FLAGS', newFlags);
    }
  },
  async actionPlanFlag({ state, commit }, { id, action }) {
    const { data } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: ACTION_PLAN_FLAG,
      variables: {
        id,
        action,
      },
    });
    if (data.actionPlanFlag) {
      const newFlag = data.actionPlanFlag;
      newFlag.title = newFlag.flag?.slug
        ? this.app.i18n.t(`flags.${newFlag.flag.slug}.title`)
        : '';
      newFlag.description = newFlag.flag?.slug
        ? this.app.i18n.t(`flags.${newFlag.flag.slug}.description`)
        : '';

      const newFlagIndex = state.planFlags.findIndex(
        (flag) => flag.id === newFlag.id
      );
      recursiveRemoveKey(data, '__typename');
      const newPlanFlags = [...state.planFlags];
      newPlanFlags[newFlagIndex] = data;
      commit('SET_PLAN_FLAGS', newPlanFlags);
    }
  },
};
