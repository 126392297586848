import { trySetLocalStorage } from '@/utilities';
import {
  GET_STARTED_VARIATION as GET_STARTED_VARIATION_VALUE,
  WILL_TIERS_SLICE_TWO_ONE_VARIATION,
} from '@/utilities/constants';

const FREE_WILL_WEEK = 'free-will-week';
const PRE_PAID_CONTRACT_FLOW = 'pre-paid-contract-flow';
const EXECUTOR_FEES_CALCULATOR_PANEL = 'executor-fees-calculator-panel';
const ENABLE_MASQUERADE = 'enable-masquerade';
const LIFE_INSURANCE_WILL_FLOW_UPSELL = 'life-insurance-will-flow-upsell';
const LIFE_INSURANCE_UPSELL_BRAND = 'life-insurance-upsell-brand';
const CARDLESS_CHECKOUT = 'cardless-checkout';
const ONBOARDING_NEEDS_ASSESSMENT = 'onboarding-needs-assessment';
const PTG_ACT_EXECUTOR_OPTION = 'ptg-act-executor-option';
const COMBINED_FREE_WILL_CHECKOUT_SUBMISSION =
  'combined-free-will-checkout-submission';
const WILL_TIERS_SLICE_TWO_ONE = 'will-tiers-slice-two-one';
const WILL_TIERS_SLICE_THREE = 'will-tiers-slice-three';
const TOKENISE_PERSON_DATA = 'tokenise-person-data';
const GET_STARTED_VARIATION = 'get-started-variation';
const FREE_CART_SUBSCRIPTION_AUTO_SELECTED =
  'free-cart-subscription-auto-selected';
const CONTINUE_WITHOUT_SIGNUP_BUTTON = 'continue-without-signup-button';
const CONTACT_SELECTOR_INVITE_ALTERNATIVE_TEXT =
  'contact-selector-invite-alternative-text';
const CONTACT_INFORMED_ROLE_FORM = 'contact-informed-role-form';
const INTENTION_TO_SIGN_ENABLED = 'intention-to-sign-enabled';
const BINDING_DEATH_NOMINATION_ENABLED = 'binding-death-nomination-enabled';
const PRE_SIGNUP_ONBOARDING = 'pre-signup-onboarding';
const INVITE_BUY_GIFT_WILL_NOTIFICATION = 'invite-buy-gift-will-notification';

export const FeatureFlagDefaults = {
  [FREE_WILL_WEEK]: false,
  [PRE_PAID_CONTRACT_FLOW]: false,
  [EXECUTOR_FEES_CALCULATOR_PANEL]: false,
  [ENABLE_MASQUERADE]: false,
  [LIFE_INSURANCE_WILL_FLOW_UPSELL]: false,
  [CARDLESS_CHECKOUT]: false,
  [LIFE_INSURANCE_UPSELL_BRAND]: false,
  [ONBOARDING_NEEDS_ASSESSMENT]: false,
  [PTG_ACT_EXECUTOR_OPTION]: false,
  [COMBINED_FREE_WILL_CHECKOUT_SUBMISSION]: false,
  [WILL_TIERS_SLICE_TWO_ONE]: WILL_TIERS_SLICE_TWO_ONE_VARIATION.CONTROL,
  [WILL_TIERS_SLICE_THREE]: false,
  [TOKENISE_PERSON_DATA]: false,
  [GET_STARTED_VARIATION]: GET_STARTED_VARIATION_VALUE.CONTROL,
  [FREE_CART_SUBSCRIPTION_AUTO_SELECTED]: true,
  [CONTINUE_WITHOUT_SIGNUP_BUTTON]: false,
  [CONTACT_SELECTOR_INVITE_ALTERNATIVE_TEXT]: false,
  [CONTACT_INFORMED_ROLE_FORM]: false,
  [INTENTION_TO_SIGN_ENABLED]: false,
  [BINDING_DEATH_NOMINATION_ENABLED]: false,
  [PRE_SIGNUP_ONBOARDING]: false,
  [INVITE_BUY_GIFT_WILL_NOTIFICATION]: false,
};

// This plugin will run on client side
// and will set the cookie if not exists
export default (ctx, inject) => {
  inject('ff', {
    refreshFeatureFlags: () => {
      refreshFeatureFlags(ctx);
    },
    isFreeWillWeek: () => ctx.store.state.featureFlags[FREE_WILL_WEEK],
    prePaidContractFlowEnabled: () =>
      ctx.store.state.featureFlags[PRE_PAID_CONTRACT_FLOW],
    executorFeesCalculatorPanel: () =>
      ctx.store.state.featureFlags[EXECUTOR_FEES_CALCULATOR_PANEL],
    enableMasquerade: () => ctx.store.state.featureFlags[ENABLE_MASQUERADE],
    lifeInsuranceWillFlowUpsell: () =>
      ctx.store.state.featureFlags[LIFE_INSURANCE_WILL_FLOW_UPSELL],
    lifeInsuranceBrandUpsellEnabled: () =>
      ctx.store.state.featureFlags[LIFE_INSURANCE_UPSELL_BRAND],
    cardlessCheckout: () => ctx.store.state.featureFlags[CARDLESS_CHECKOUT],
    onboardingNeedsAssessment: () =>
      ctx.store.state.featureFlags[ONBOARDING_NEEDS_ASSESSMENT],
    ptgActExecutorOption: () =>
      ctx.store.state.featureFlags[PTG_ACT_EXECUTOR_OPTION],
    combinedFreeWillCheckoutSubmission: () =>
      ctx.store.state.featureFlags[COMBINED_FREE_WILL_CHECKOUT_SUBMISSION],
    willTiersSliceTwoOne: () =>
      ctx.store.state.featureFlags[WILL_TIERS_SLICE_TWO_ONE],
    willTiersSliceTwoOneVariation: () => {
      const value = ctx.store.state.featureFlags[WILL_TIERS_SLICE_TWO_ONE];
      return {
        control: value === WILL_TIERS_SLICE_TWO_ONE_VARIATION.CONTROL,
        variationOne:
          value === WILL_TIERS_SLICE_TWO_ONE_VARIATION.VARIATION_ONE,
        variationTwo:
          value === WILL_TIERS_SLICE_TWO_ONE_VARIATION.VARIATION_TWO,
      };
    },

    willTiersSliceThree: () =>
      ctx.store.state.featureFlags[WILL_TIERS_SLICE_THREE],
    tokenisePersonData: () =>
      ctx.store.state.featureFlags[TOKENISE_PERSON_DATA],
    getStartedVariation: () =>
      ctx.store.state.featureFlags[GET_STARTED_VARIATION],
    freeCartSubscriptionAutoSelected: () =>
      ctx.store.state.featureFlags[FREE_CART_SUBSCRIPTION_AUTO_SELECTED],
    continueWithoutSignupButton: () =>
      ctx.store.state.featureFlags[CONTINUE_WITHOUT_SIGNUP_BUTTON],
    contactSelectorInviteAlternativeText: () =>
      ctx.store.state.featureFlags[CONTACT_SELECTOR_INVITE_ALTERNATIVE_TEXT],
    contactInformedRoleForm: () =>
      ctx.store.state.featureFlags[CONTACT_INFORMED_ROLE_FORM],
    intentionToSignEnabled: () =>
      ctx.store.state.featureFlags[INTENTION_TO_SIGN_ENABLED],
    bindingDeathNominationEnabled: () =>
      ctx.store.state.featureFlags[BINDING_DEATH_NOMINATION_ENABLED],
    preSignupOnboarding: () =>
      ctx.store.state.featureFlags[PRE_SIGNUP_ONBOARDING],
    giftWillNotifyEnabled: () =>
      ctx.store.state.featureFlags[INVITE_BUY_GIFT_WILL_NOTIFICATION],
  });

  // On the client side, right before all components begin to mount
  // Dispatch a call to set the feature flags store
  // Note that calling the API on client side may cause a "glitch"
  refreshFeatureFlags(ctx);
};

function refreshFeatureFlags(ctx) {
  if (typeof fetch === 'function') {
    fetch(`${process.env.SAFEWILL_BACKEND_API}/feature-flags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ctx.$auth.strategy.token.get(),
      },
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((result) => {
        ctx.store.commit('setFeatureFlags', result);

        // Set the local storage so that it loads faster next time
        for (const key in result) {
          trySetLocalStorage(key, result[key]);
        }
      })
      .catch(() => {
        console.error('Unable to refresh feature flags');
      });
  }
}
