import cookies from 'js-cookie';
import { getBaseUrl, isAdminOrHigher, getAnonymousProfile } from '@/utilities';
import { removeLegacyCookie } from '@/modules/cookies';
import {
  sanitizeLogInRedirect,
  sanitizeSignUpRedirect,
} from '@/utilities/allowed-redirects';

import LOGIN_USER from '@/graphql/mutations/LoginUser';
import GET_WILL_QUERY from '@/graphql/queries/GetWill';
import GET_PRODUCTS_PURCHASED_INIT_QUERY from '@/graphql/queries/GetProductsPurchasedInit';
import GET_PARTNERSHIP_APP_DATA from '@/graphql/queries/GetPartnershipAppData';

const ADMIN_DASHBOARD_PATH = '/sw/admin';

export default async function ({
  app,
  $auth,
  store: { state, commit, dispatch, getters },
  $ff,
}) {
  const client = app.apolloProvider.defaultClient;
  const currentPath = window.location.pathname;
  const currentPathElements = currentPath.split('/');
  let affiliateFromUrl = currentPathElements[1];
  if (app.i18n.localeCodes.includes(affiliateFromUrl)) {
    affiliateFromUrl = currentPathElements[2];
  }
  const cookie = cookies.get('affiliate_slug');

  let affiliateSlug;
  if (currentPathElements.includes('get-started')) {
    affiliateSlug = affiliateFromUrl || cookie;
  } else {
    affiliateSlug = cookie;
  }

  if ($auth.loggedIn) {
    const token = $auth.strategy.token.get() || '';

    await app.$apolloHelpers.onLogin(token.split(' ')[1]);

    try {
      const { data } = await client.mutate({ mutation: LOGIN_USER });
      const user = data.loginUser.user;

      if (user) {
        if (window.analytics && data.loginUser.action === 'login') {
          window.analytics?.track('login', {
            identity_provider: data.loginUser.identityProvider,
          });
        }

        if (affiliateSlug || user.affiliateId) {
          await dispatch('application/affiliate/getAffiliateBranding', {
            affiliateId: user.affiliateId,
            affiliateSlug,
          });

          affiliateSlug = getters['application/affiliate/slug'];
          cookies.remove('affiliate_slug');
        }

        commit('setEmail', user.email);
        commit('setRole', user.role);
        commit('setUserId', user.id);
        commit('setVerified', user.verified);
        commit('setToken', token);
        commit('setAffiliateUserId', user.affiliate_user_id);
        commit(
          'user/cart/SET_SUBSCRIPTION_AUTO_RENEWAL',
          $ff.freeCartSubscriptionAutoSelected()
        );

        await dispatch('user/contacts/getDirectoryPersonsByOwnerId', {
          ownerId: user.id,
          personId: user?.person_id,
        });
        await dispatch('tool/getTools', {
          ownerId: user.id,
        });

        const willHelpAssessmentTool = getters['tool/willHelpAssessmentTool'];
        if (willHelpAssessmentTool) {
          dispatch('vault-item/getWillHelpAssessmentVaultItems', {
            ids: willHelpAssessmentTool.vaultItemIds,
          });
        }

        if (data.loginUser.action === 'sign_up') {
          await dispatch('external-identifiers/createAnalyticIdentifiers');
        } else {
          await dispatch('external-identifiers/getAnalyticIdentifiers');
        }
        await dispatch('application/products/getProducts');
        await dispatch('user/invites/getInvites');
      } else {
        removeLegacyCookie();
        app.$apolloHelpers.onLogout();
        dispatch('resetUserState');
        dispatch('resetCheckoutState');
        dispatch('resetUI');
        await $auth.logout();
      }
    } catch (e) {
      console.error(e.message);
      removeLegacyCookie();
      app.$apolloHelpers.onLogout();
      dispatch('resetUserState');
      dispatch('resetCheckoutState');
      dispatch('resetUI');
      await $auth.logout();
    }

    try {
      let redirectPath;

      if (isAdminOrHigher(state.role)) {
        if (!window.location.pathname.includes(ADMIN_DASHBOARD_PATH)) {
          redirectPath = ADMIN_DASHBOARD_PATH;
        }
      } else {
        const { data } = await client.query({ query: GET_WILL_QUERY });
        if (data.getWill.will) {
          dispatch('setWillId', data.getWill.will.id);
          dispatch('setWillStatus', data.getWill.will.status);
          dispatch('will/setData', data.getWill.will);
          dispatch('will/getAllWillRelatedData');
        }

        const {
          data: { poa },
        } = await client.query({
          query: GET_PRODUCTS_PURCHASED_INIT_QUERY,
        });
        if (poa) {
          dispatch('user/poa/setPoaId', poa.id);
          dispatch('user/poa/getAllPoaRelatedData');
        }

        await dispatch('user/coupon/getCouponLatestReferral');
        await dispatch('application/causes/getCauses');
        await dispatch('user/orders/getOrders');
        await dispatch('user/flags/getPlanFlags', {
          planId: data.getWill.will.id,
        });
        await dispatch('user/cart/getCartItems');
        await dispatch('application/prices/getProductPrices');

        const signUpRedirectCookie = cookies.get('signup_redirect');
        const redirectCookie = cookies.get('redirect');

        if (redirectCookie) {
          redirectPath = sanitizeLogInRedirect(redirectCookie);
        } else if (signUpRedirectCookie) {
          redirectPath = sanitizeSignUpRedirect(signUpRedirectCookie);
        }

        cookies.remove('signup_redirect');
        cookies.remove('redirect');

        const { data: partnershipAppData } = await client.query({
          query: GET_PARTNERSHIP_APP_DATA,
        });
        dispatch('setCharitySource', partnershipAppData.getPartnershipAppData);

        if (affiliateSlug) {
          await dispatch(
            'application/affiliate/getAffiliateFeatures',
            affiliateSlug
          );
        }

        const hasUtm = cookies.get('has_utm');
        const utm = getAnonymousProfile()?.utm || {};
        const utmParams = new URLSearchParams(utm).toString();
        cookies.remove('has_utm');

        if (hasUtm && !window.location.search.includes(utmParams)) {
          window.location.search = `?${utmParams}`;
        }
      }

      if (redirectPath && window.location.pathname !== redirectPath) {
        window.location.href = getBaseUrl() + redirectPath;
      }
    } catch (e) {
      console.error(e.message);
    }
  } else if (affiliateSlug) {
    await dispatch('application/affiliate/getAffiliateBranding', {
      affiliateSlug,
    });
  }
}
