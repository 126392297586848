import { mapActions, mapGetters } from 'vuex';

import ADD_EXECUTOR_MUTATION from '@/graphql/mutations/AddExecutor';
import REMOVE_EXECUTOR_MUTATION from '@/graphql/mutations/RemoveExecutor';

export default {
  name: 'MixinsApolloExecutors',
  computed: {
    ...mapGetters(['willId']),
    ...mapGetters('will', ['executors']),
    primaryExecutors() {
      return this.executors.filter((executor) => executor.type === 'primary');
    },
    backupExecutors() {
      return this.executors.filter((executor) => executor.type === 'backup');
    },
  },
  methods: {
    ...mapActions('will', ['setExecutors', 'getExecutorsData']),
    isExecutor(directoryPerson) {
      return !!this.executors.find(
        (executor) => executor.directoryPerson.id === directoryPerson.id
      );
    },
    isPrimaryExecutor(directoryPerson) {
      return !!this.primaryExecutors.find(
        (executor) => executor.directoryPerson.id === directoryPerson.id
      );
    },
    isBackupExecutor(directoryPerson) {
      return !!this.backupExecutors.find(
        (executor) => executor.directoryPerson.id === directoryPerson.id
      );
    },
    async addExecutor(directoryPersonId, type) {
      const {
        data: { addExecutor },
      } = await this.$apollo.mutate({
        mutation: ADD_EXECUTOR_MUTATION,
        variables: {
          willId: this.willId,
          directoryPersonId,
          type,
        },
      });

      await this.setExecutors([...this.executors, addExecutor]);
    },
    async removeExecutor(directoryPerson) {
      const executor = this.executors.find(
        (executor) => executor.directoryPerson.id === directoryPerson.id
      );
      if (executor) {
        await this.$apollo.mutate({
          mutation: REMOVE_EXECUTOR_MUTATION,
          variables: {
            id: executor.id,
            willId: this.willId,
          },
        });

        await this.setExecutors(
          this.executors.filter((m) => m.id !== executor.id)
        );
      } else {
        await this.getExecutorsData();
      }
    },
  },
};
