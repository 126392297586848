import { getPathWithoutLocale } from '@/utilities/index';

export default function ({ redirect, route, i18n, store }) {
  const { fullPath } = route;
  const pathWithoutLocale = getPathWithoutLocale(fullPath, i18n.localeCodes);
  const affiliateSlug = store.getters['application/affiliate/slug'];

  if (
    process.env.MAINTENANCE_MODE === 'true' &&
    `/${affiliateSlug}/maintenance` !== `${pathWithoutLocale}`
  ) {
    return redirect(`/${affiliateSlug}/maintenance`);
  }

  if (
    process.env.MAINTENANCE_MODE !== 'true' &&
    `/${affiliateSlug}/maintenance` === `${pathWithoutLocale}`
  ) {
    return redirect(`/${affiliateSlug}/`);
  }
}
