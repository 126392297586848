import gql from 'graphql-tag';

export default gql`
  query GetPartnershipBranding($id: ID, $slug: String) {
    getPartnershipBranding(id: $id, slug: $slug) {
      getStartedTitleOne
      getStartedDescriptionOne
      getStartedTitleTwo
      getStartedDescriptionTwo
      getStartedTitleThree
      getStartedDescriptionThree
      getStartedAffiliateLogoShown
      appHeaderAffiliateLogoShown
      logo
      slug
      branding {
        theme
        darker
        lighter
        landingImage
      }
    }
  }
`;
