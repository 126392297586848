const productDependencies = {
  WILL: ['LEGAL_ADVICE_BOOKING', 'PARTNER_WILL'],
  WILL_TIER_TWO: ['PARTNER_WILL'],
};
const singlePurchaseProducts = [
  'POA',
  'WILL',
  'WILL_TIER_TWO',
  'LEGAL_ADVICE_BOOKING',
  'PARTNER_WILL',
  'PARTNER_POA',
];
const isUnlockProduct = (code) => ['UNLOCK', 'RECENT_UNLOCK'].includes(code);
const isSubscriptionProduct = (code) =>
  ['SUBSCRIPTION', 'UNLOCK', 'RECENT_UNLOCK'].includes(code);
const isSubscription = (code) => code === 'SUBSCRIPTION';

export default {
  subscriptionAutoRenewal: (state) => state.subscriptionAutoRenewal,
  showSubscriptionAutoRenewalInCheckout: (state) =>
    state.showSubscriptionAutoRenewalInCheckout,
  discountCode: (state) => state.discountCode,
  isCartEmpty: (state) => state.cartItems.length === 0,
  cartSize: (state) => state.cartItems.length,
  cartItems: (state) => state.cartItems,
  cartItemCodes: (state) => state.cartItems.map((item) => item.product.code),
  cartBasePrice: (state) => state.cartPriceData?.basePrice,
  cartFinalPrice: (state) => state.cartPriceData?.finalPrice,
  isFreeCart: (state) =>
    state.cartItems.length !== 0 && state.cartPriceData?.finalPrice === 0,
  cartItemFinalPrice: (state) => (code) =>
    state.cartPriceData?.products?.[code]?.finalPrice,
  cartItemBasePrice: (state) => (code) =>
    state.cartPriceData?.products?.[code]?.basePrice,
  cartItemPrices: (state) =>
    Object.entries(state.cartPriceData?.products ?? {}).map(
      ([code, price]) => ({
        product: code,
        ...price,
      })
    ),
  productRemoval: (state) => state.productRemoval,
  productDependencies: () => (code) => {
    return productDependencies[code] ?? [];
  },
  isInCart: (_state, getters) => (code) => {
    return getters.cartItemCodes.includes(code);
  },
  canAddToCart:
    (state, getters, _rootState, rootGetters) =>
    (code, codesAddedTogether = []) => {
      if (
        singlePurchaseProducts.includes(code) &&
        rootGetters['user/orders/isProductPurchased'](code)
      ) {
        return false;
      }
      if (
        state.cartItems.find(({ product }) => code === product.code)?.product
          ?.singlePurchase
      ) {
        return false;
      }
      if (isUnlockProduct(code)) {
        return !getters.cartItemCodes.some(isSubscriptionProduct);
      } else if (isSubscription(code)) {
        return !getters.cartItemCodes.some(isSubscription);
      }
      const invertedDependencies = Object.keys(productDependencies).reduce(
        (acc, key) => {
          productDependencies[key].forEach(
            (dependency) =>
              (acc[dependency] = [...(acc[dependency] ?? []), key])
          );
          return acc;
        },
        {}
      );
      const isRequiredProductsInCartOrPurchased =
        invertedDependencies[code]?.some((requiredProduct) => {
          return (
            codesAddedTogether.includes(requiredProduct) ||
            getters.isInCart(requiredProduct) ||
            rootGetters['user/orders/isProductPurchased'](requiredProduct)
          );
        }) ?? true;
      return isRequiredProductsInCartOrPurchased;
    },
};
