import { mapActions, mapGetters } from 'vuex';

import ADD_GUARDIAN_MUTATION from '@/graphql/mutations/AddGuardian';
import GET_GUARDIANS_QUERY from '@/graphql/queries/GetGuardians';
import REMOVE_GUARDIAN_MUTATION from '@/graphql/mutations/RemoveGuardian';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloGuardians',
  apollo: {
    guardians: {
      query: GET_GUARDIANS_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data?.getGuardians || [],
      skip() {
        return !this.token || !this.willId;
      },
      fetchPolicy: 'no-cache',
      error,
    },
  },
  data() {
    return {
      guardians: [],
    };
  },
  watch: {
    async guardians(guardians) {
      // Enrich directory persons and set guardians in store
      const enrichedGuardians = await this.setGuardians(guardians);

      guardians.forEach((_, index) => {
        guardians[index] = enrichedGuardians[index];
      });
    },
  },
  computed: {
    ...mapGetters(['willId', 'token']),
    getGuardiansQuery() {
      return {
        query: GET_GUARDIANS_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
    primaryGuardians() {
      return this.guardians.filter((guardian) => guardian.type === 'primary');
    },
    backupGuardians() {
      return this.guardians.filter((guardian) => guardian.type === 'backup');
    },
  },
  methods: {
    ...mapActions('will', ['setGuardians']),
    isGuardian(contact) {
      return !!this.guardians.find(
        (guardian) => guardian.directoryPerson.id === contact.id
      );
    },
    isPrimaryGuardian(contact) {
      return !!this.primaryGuardians.find(
        (guardian) => guardian.directoryPerson.id === contact.id
      );
    },
    isBackupGuardian(contact) {
      return !!this.backupGuardians.find(
        (guardian) => guardian.directoryPerson.id === contact.id
      );
    },
    async addGuardian(contact, type) {
      await this.$apollo.mutate({
        mutation: ADD_GUARDIAN_MUTATION,
        variables: {
          willId: this.willId,
          directoryPersonId: contact.id,
          type,
        },
        update: (store, { data: { addGuardian } }) => {
          const data = store.readQuery(this.getGuardiansQuery);
          data.getGuardians.push(addGuardian);

          store.writeQuery({
            ...this.getGuardiansQuery,
            data,
          });
        },
      });
    },
    async removeGuardian(contact) {
      const guardian = this.guardians.find(
        (guardian) => guardian.directoryPerson.id === contact.id
      );

      if (guardian) {
        await this.$apollo.mutate({
          mutation: REMOVE_GUARDIAN_MUTATION,
          variables: {
            id: guardian.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getGuardiansQuery);
            const index = data.getGuardians.findIndex(
              (m) => m.id === guardian.id
            );

            if (index !== -1) {
              data.getGuardians.splice(index, 1);

              store.writeQuery({
                ...this.getGuardiansQuery,
                data,
              });
            }
          },
        });
      }
    },
    async removeAllGuardians() {
      await Promise.all(
        this.guardians.map((guardian) => {
          return this.removeGuardian(guardian.directoryPerson);
        })
      );
    },
  },
};
