import { mapActions, mapGetters } from 'vuex';

import ADD_ASSET_MUTATION from '@/graphql/mutations/AddAsset';
import GET_ASSETS_QUERY from '@/graphql/queries/GetAssets';
import REMOVE_ASSET_MUTATION from '@/graphql/mutations/RemoveAsset';
import UPDATE_ASSET_MUTATION from '@/graphql/mutations/UpdateAsset';
import { error } from '@/mixins/apollo';

export default {
  name: 'MixinsApolloAssets',
  apollo: {
    assets: {
      query: GET_ASSETS_QUERY,
      variables() {
        return {
          willId: this.willId,
        };
      },
      update: (data) => data.getAssets,
      result({ data }) {
        if (data?.getAssets) {
          this.setAssets(data.getAssets);
        } else {
          console.error('Unexpected data structure for data.getAssets:', data);
        }
      },
      skip() {
        return !this.token || !this.willId;
      },
      error,
    },
  },
  data() {
    return {
      assets: [],
    };
  },
  computed: {
    ...mapGetters(['willId', 'token']),
    getAssetsQuery() {
      return {
        query: GET_ASSETS_QUERY,
        variables: {
          willId: this.willId,
        },
      };
    },
  },
  methods: {
    ...mapActions('will', ['setAssets']),
    isAsset(asset) {
      return !!this.assets.find((a) => a.id === asset.id);
    },
    async addAsset(meta) {
      await this.$apollo
        .mutate({
          mutation: ADD_ASSET_MUTATION,
          variables: {
            willId: this.willId,
            meta,
          },
          update: (store, { data: { addAsset } }) => {
            const data = store.readQuery(this.getAssetsQuery);
            data.getAssets.push(addAsset);

            store.writeQuery({
              ...this.getAssetsQuery,
              data,
            });
          },
        })
        .catch(error);
    },
    async removeAsset(asset) {
      await this.$apollo
        .mutate({
          mutation: REMOVE_ASSET_MUTATION,
          variables: {
            id: asset.id,
            willId: this.willId,
          },
          update: (store) => {
            const data = store.readQuery(this.getAssetsQuery);
            const index = data.getAssets.findIndex((m) => m.id === asset.id);

            if (index !== -1) {
              data.getAssets.splice(index, 1);

              store.writeQuery({
                ...this.getAssetsQuery,
                data,
              });
            }
          },
        })
        .catch(error);
    },
    async updateAsset(asset, meta) {
      await this.$apollo
        .mutate({
          mutation: UPDATE_ASSET_MUTATION,
          variables: {
            id: asset.id,
            meta,
            willId: this.willId,
          },
        })
        .catch(error);
    },
  },
};
