import { FeatureFlagDefaults } from '@/plugins/featureFlags';
import { tryGetFromLocalStorage } from '@/utilities';
import GET_PARTNERSHIP_APP_DATA from '@/graphql/queries/GetPartnershipAppData';

export const state = () => {
  const featureFlags = {};
  for (const key in FeatureFlagDefaults) {
    featureFlags[key] = tryGetFromLocalStorage(key, FeatureFlagDefaults[key]);
  }
  return {
    affiliateUserId: null,
    charityParents: {},
    charitySource: [],
    email: null,
    featureFlags, // Feature Flags are set in store so that it is reactive and global
    password: null,
    role: null,
    token: null,
    trackingActive: false,
    twoFactorEnabled: false,
    userId: null,
    verified: null,
    willId: null,
    willStatus: null,
  };
};

export const getters = {
  email: (state) => state.email,
  role: (state) => state.role,
  token: (state) => state.token,
  password: (state) => state.password,
  twoFactorEnabled: (state) => state.twoFactorEnabled,
  trackingActive: (state) => state.trackingActive,
  userId: (state) => state.userId,
  willId: (state) => state.willId,
  willStatus: (state) => state.willStatus,
  verified: (state) => state.verified,
  isFreeWillWeek: (state) => !!state.featureFlags['free-will-week'],
  featuredFive: (state) =>
    state.charitySource.filter((partnership) => {
      return partnership.featuredFive === true;
    }),
  charitySource: (state) => state.charitySource,
  charityParents: (state) => state.charityParents,
  affiliateUserId: (state) => state.affiliateUserId,
};

export const mutations = {
  setEmail(state, value) {
    state.email = value;
  },
  setRole(state, value) {
    state.role = value;
  },
  setToken(state, value) {
    state.token = value;
  },
  setPassword(state, value) {
    state.password = value;
  },
  setTwoFactorEnabled(state, value) {
    state.twoFactorEnabled = value;
  },
  setTrackingActive(state, value) {
    state.trackingActive = value;
  },
  setUserId(state, value) {
    state.userId = value;
  },
  setVerified(state, value) {
    state.verified = value;
  },
  setWillId(state, value) {
    state.willId = value;
  },
  setWillStatus(state, value) {
    state.willStatus = value;
  },
  setCharitySource(state, value) {
    state.charitySource = value;
  },
  setCharityParents(state, value) {
    state.charityParents = value;
  },
  setFeatureFlags(state, value) {
    state.featureFlags = value;
  },
  setAffiliateUserId(state, value) {
    state.affiliateUserId = value;
  },
};

export const actions = {
  setEmail({ commit }, value) {
    commit('setEmail', value);
  },
  setRole({ commit }, value) {
    commit('setRole', value);
  },
  setToken({ commit }, value) {
    commit('setToken', value);
  },
  setPassword({ commit }, value) {
    commit('setPassword', value);
  },
  setTwoFactorEnabled({ commit }, value) {
    commit('setTwoFactorEnabled', value);
  },
  setTrackingActive({ commit }, value) {
    commit('setTrackingActive', value);
  },
  setUserId({ commit }, value) {
    commit('setUserId', value);
  },
  setVerified({ commit }, value) {
    commit('setVerified', value);
  },
  setWillId({ commit }, value) {
    commit('setWillId', value);
  },
  setWillStatus({ commit, dispatch }, value) {
    commit('setWillStatus', value);
    dispatch('user/progress/setWillProgress', null, { root: true });
  },
  setCharitySource({ commit }, value) {
    const parentPartnerships = {};
    value.forEach((item) => {
      if (item.parentPartnershipId && item.region) {
        parentPartnerships[item.parentPartnershipId] = {
          ...parentPartnerships[item.parentPartnershipId],
          [item.region.toLowerCase()]: item,
        };
      }
    });
    commit('setCharitySource', value);
    commit('setCharityParents', parentPartnerships);
  },
  resetUserState({ commit }) {
    commit('setEmail', null);
    commit('setRole', null);
    commit('setToken', null);
    commit('setPassword', null);
    commit('setTwoFactorEnabled', false);
    commit('setTrackingActive', false);
    commit('setUserId', null);
    commit('setVerified', null);
    commit('setWillId', null);
    commit('setWillStatus', null);
    commit('admin/masquerade/SET_MASQUERADING', null, { root: true });
    commit('setCharitySource', []);
  },
  setAffiliateUserId({ commit }, value) {
    commit('setAffiliateUserId', value);
  },
  async refreshCharitySource({ commit }) {
    const { data } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: GET_PARTNERSHIP_APP_DATA,
    });

    commit('setCharitySource', data.getPartnershipAppData);
  },
};
