import camelCase from 'lodash/camelCase';
import { isStrictBoolean } from '@/utilities';

export const state = () => ({
  logoVariant: 'safewill',
  navigationVariant: 'full',
  activeProduct: null,
  activeSection: null,
  confirmPasswordModalIsOpen: false,
  submitHelperModalIsOpen: false,
  subscriptionExpiredModalIsOpen: false,
  unlockWillModalIsOpen: false,
  glossaryPanelIsOpen: false,
  charityPanelIsOpen: false,
  giftWizardIsOpen: false,
  petPanelIsOpen: false,
});

export const getters = {
  logoVariant: (state) => state.logoVariant,
  navigationVariant: (state) => state.navigationVariant,
  activeProduct: (state) => state.activeProduct,
  activeSection: (state) => state.activeSection,
  confirmPasswordModalIsOpen: (state) => state.confirmPasswordModalIsOpen,
  submitHelperModalIsOpen: (state) => state.submitHelperModalIsOpen,
  subscriptionExpiredModalIsOpen: (state) =>
    state.subscriptionExpiredModalIsOpen,
  unlockWillModalIsOpen: (state) => state.unlockWillModalIsOpen,
  glossaryPanelIsOpen: (state) => state.glossaryPanelIsOpen,
  charityPanelIsOpen: (state) => state.charityPanelIsOpen,
  giftWizardIsOpen: (state) => state.giftWizardIsOpen,
  petPanelIsOpen: (state) => state.petPanelIsOpen,
  charityConsentUIRequired: (_state, _getters, _rootState, rootGetters) => {
    const isMasquerading = !!rootGetters['admin/masquerade/isMasquerading'];
    const hasAnsweredConsentQuestion = isStrictBoolean(
      rootGetters['will/consentsToCharityNotification']
    );

    if (!isMasquerading && !hasAnsweredConsentQuestion) {
      const hasCharitiesInEstate = rootGetters['will/beneficiaries'].some(
        (beneficiary) =>
          beneficiary.charity !== null ||
          beneficiary.backup?.some((backup) => backup.charity !== null)
      );
      const hasCharitiesInGifts = rootGetters['will/gifts'].some(
        (gift) => gift.charity !== null
      );
      if (hasCharitiesInEstate || hasCharitiesInGifts) {
        const userHasSubmittedWill = !['IN_PROGRESS', 'ARCHIVED'].includes(
          rootGetters.willStatus
        );
        if (!userHasSubmittedWill) {
          return 'page';
        } else {
          return 'modal';
        }
      }
    }
    return null;
  },
  shouldShowCharityConsentPage: (_state, getters) => {
    return getters.charityConsentUIRequired === 'page';
  },
  shouldShowCharityConsentModal: (_state, getters) => {
    return getters.charityConsentUIRequired === 'modal';
  },
};

export const mutations = {
  SET_LOGO_VARIANT(state, value) {
    state.logoVariant = value;
  },
  SET_NAVIGATION_VARIANT(state, value) {
    state.navigationVariant = value;
  },
  SET_ACTIVE_PRODUCT(state, value) {
    state.activeProduct = value || null;
  },
  SET_ACTION_SECTION(state, value) {
    state.activeSection = value || null;
  },
  SET_CONFIRM_PASSWORD_MODAL_IS_OPEN(state, value) {
    state.confirmPasswordModalIsOpen = value;
  },
  SET_SUBMIT_HELPER_MODAL_IS_OPEN(state, value) {
    state.submitHelperModalIsOpen = value;
  },
  SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN(state, value) {
    state.subscriptionExpiredModalIsOpen = value;
  },
  SET_UNLOCK_WILL_MODAL_IS_OPEN(state, value) {
    state.unlockWillModalIsOpen = value;
  },
  SET_GLOSSARY_PANEL_IS_OPEN(state, value) {
    state.glossaryPanelIsOpen = value;
  },
  SET_CHARITY_PANEL_IS_OPEN(state, value) {
    state.charityPanelIsOpen = value;
  },
  SET_GIFT_WIZARD_IS_OPEN(state, value) {
    state.giftWizardIsOpen = value;
  },
  SET_PET_PANEL_IS_OPEN(state, value) {
    state.petPanelIsOpen = value;
  },
};

export const actions = {
  setWhereabouts({ rootGetters, commit }, route) {
    const slug = rootGetters['application/affiliate/slug'];
    const path = route.path;

    let activeProduct = null;
    if (
      path.includes(`/${slug}/onboarding/user`) ||
      path.includes(`/${slug}/onboarding/recommendation`)
    ) {
      activeProduct = 'onboarding';
    } else if (path.includes(`/${slug}/will`)) {
      activeProduct = 'will';
    } else if (
      path.includes(`/${slug}/onboarding/poa`) ||
      path.includes(`/${slug}/poa`)
    ) {
      activeProduct = 'poa';
    } else if (
      path.includes(`/${slug}/onboarding/end-of-life`) ||
      path.includes(`/${slug}/end-of-life`)
    ) {
      activeProduct = 'end-of-life';
    }

    let activeSection = null;
    if (activeProduct && path.includes(activeProduct)) {
      const parts = path.split(activeProduct)[1]?.split('/');
      activeSection = parts && parts.length > 1 ? parts[1].split('/')[0] : null;
    }
    activeProduct = activeProduct ? camelCase(activeProduct) : null;
    activeSection = activeSection ? camelCase(activeSection) : null;
    commit('SET_ACTIVE_PRODUCT', activeProduct);
    commit('SET_ACTION_SECTION', activeSection);
  },
  setLogoVariant({ commit }, value) {
    commit('SET_LOGO_VARIANT', value);
  },
  setNavigationVariant({ commit }, value) {
    commit('SET_NAVIGATION_VARIANT', value);
  },
  showConfirmPasswordModal({ commit }) {
    commit('SET_CONFIRM_PASSWORD_MODAL_IS_OPEN', true);
  },
  hideConfirmPasswordModal({ commit }) {
    commit('SET_CONFIRM_PASSWORD_MODAL_IS_OPEN', false);
  },
  showSubmitHelperModal({ commit }) {
    commit('SET_SUBMIT_HELPER_MODAL_IS_OPEN', true);
  },
  hideSubmitHelperModal({ commit }) {
    commit('SET_SUBMIT_HELPER_MODAL_IS_OPEN', false);
  },
  showSubscriptionExpiredModal({ commit }) {
    commit('SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN', true);
  },
  hideSubscriptionExpiredModal({ commit }) {
    commit('SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN', false);
  },
  showUnlockWillModal({ commit }) {
    commit('SET_UNLOCK_WILL_MODAL_IS_OPEN', true);
  },
  hideUnlockWillModal({ commit }) {
    commit('SET_UNLOCK_WILL_MODAL_IS_OPEN', false);
  },
  showGlossaryPanel({ commit }) {
    commit('SET_GLOSSARY_PANEL_IS_OPEN', true);
  },
  hideGlossaryPanel({ commit }) {
    commit('SET_GLOSSARY_PANEL_IS_OPEN', false);
  },
  showCharityPanel({ commit }) {
    commit('SET_CHARITY_PANEL_IS_OPEN', true);
  },
  hideCharityPanel({ commit }) {
    commit('SET_CHARITY_PANEL_IS_OPEN', false);
  },
  showGiftWizard({ commit }) {
    commit('SET_GIFT_WIZARD_IS_OPEN', true);
  },
  hideGiftWizard({ commit }) {
    commit('SET_GIFT_WIZARD_IS_OPEN', false);
  },
  showPetPanel({ commit }) {
    commit('SET_PET_PANEL_IS_OPEN', true);
  },
  hidePetPanel({ commit }) {
    commit('SET_PET_PANEL_IS_OPEN', false);
  },
  resetUI({ commit }) {
    commit('SET_LOGO_VARIANT', 'safewill');
    commit('SET_NAVIGATION_VARIANT', 'full');
    commit('SET_CONFIRM_PASSWORD_MODAL_IS_OPEN', false);
    commit('SET_SUBMIT_HELPER_MODAL_IS_OPEN', false);
    commit('SET_SUBSCRIPTION_EXPIRED_MODAL_IS_OPEN', false);
    commit('SET_UNLOCK_WILL_MODAL_IS_OPEN', false);
    commit('SET_GLOSSARY_PANEL_IS_OPEN', false);
    commit('SET_CHARITY_PANEL_IS_OPEN', false);
    commit('SET_GIFT_WIZARD_IS_OPEN', false);
    commit('SET_PET_PANEL_IS_OPEN', false);
  },
};
