import SET_SUBSCRIPTION_AUTO_RENEW from '@/graphql/mutations/SetSubscriptionAutoRenew';

export const state = () => ({
  subscription: null,
});

export const getters = {
  subscription: (state) => state.subscription,
  isValid: (state) => {
    if (!state.subscription) {
      return true;
    }
    const now = new Date();
    const expiryDate = new Date(Number(state.subscription.expiresAt));
    return now < expiryDate;
  },
};

export const mutations = {
  SET_SUBSCRIPTION(state, data) {
    state.subscription = data;
  },
};

export const actions = {
  setSubscription({ commit }, status) {
    commit('SET_SUBSCRIPTION', status);
  },
  async setAutoRenew({ rootGetters, commit }, autoRenew) {
    const {
      data: { setSubscriptionAutoRenew },
    } = await this.app.apolloProvider.defaultClient.mutate({
      mutation: SET_SUBSCRIPTION_AUTO_RENEW,
      variables: {
        userId: rootGetters.userId,
        autoRenew,
      },
    });
    commit('SET_SUBSCRIPTION', setSubscriptionAutoRenew);
  },
};
