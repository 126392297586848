import { WILL_MODULE_PATH_MAPPING } from '@/utilities/constants';
import { isStrictBoolean } from '@/utilities';

const icons = ['circle', 'incomplete-circle', 'completed', 'flag'];
const classes = ['--unstarted', '--incomplete', '--complete', '--flagged'];

function convertModuleScoreToProgress(score, target, isFlagged = false) {
  score = score.filter(Boolean).length;
  target = target.filter(Boolean).length;
  const decimal = score / target;
  let round;
  if (isFlagged) {
    round = 3;
  } else {
    round = decimal > 0 && decimal < 1 ? 1 : decimal * 2;
  }
  return {
    score,
    target,
    decimal,
    icon: icons[round],
    class: classes[round],
  };
}

function convertModuleScoresToOverallProgress(moduleScores) {
  let overallScore = 0;
  let overallTarget = 0;
  Object.keys(moduleScores).forEach((module) => {
    overallScore += moduleScores[module].score;
    overallTarget += moduleScores[module].target;
  });
  return {
    complete:
      overallScore - moduleScores.review.score ===
      overallTarget - moduleScores.review.target,
    decimal: overallScore / overallTarget,
  };
}

function showFlaggedIcons(isPlanModuleFlagged, willStatus) {
  return isPlanModuleFlagged && ['IN_PROGRESS', 'FLAGGED'].includes(willStatus);
}

const defaultProgress = {
  score: 0,
  target: 99,
  decimal: 0,
};

export const state = () => ({
  will: {
    complete: false,
    decimal: 0,
    modules: {
      aboutYourself: { ...defaultProgress },
      guardians: { ...defaultProgress },
      estate: { ...defaultProgress },
      gifts: { ...defaultProgress },
      assets: { ...defaultProgress },
      executors: { ...defaultProgress },
      funeral: { ...defaultProgress },
      review: { ...defaultProgress },
    },
  },
  poa: {
    complete: false,
    decimal: 0,
    modules: {
      aboutYourself: { ...defaultProgress },
      financial: { ...defaultProgress },
      medical: { ...defaultProgress },
      review: { ...defaultProgress },
    },
  },
});

export const getters = {
  will: (state) => state.will,
  willIsComplete: (state) => state.will.complete,
  willFirstIncompleteSectionPath: (state) => {
    const incompleteSectionKey = Object.keys(state.will?.modules ?? {}).find(
      (key) => state.will.modules[key].decimal < 1
    );
    return WILL_MODULE_PATH_MAPPING[incompleteSectionKey] ?? '/will';
  },
  poa: (state) => state.poa,
};

export const mutations = {
  setWillProgress(state, data) {
    state.will = {
      ...data,
    };
  },
  setPoaProgress(state, data) {
    state.poa = {
      ...data,
    };
  },
};

export const actions = {
  setProgress({ dispatch }) {
    dispatch('setWillProgress');
    dispatch('setPoaProgress');
  },
  setWillProgress({ commit, rootGetters }) {
    const data = rootGetters['will/data'];
    const isPlanModuleFlagged = rootGetters['user/flags/isPlanModuleFlagged'];

    const aboutYourselfProgress = () => {
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('ABOUT_YOURSELF'),
        willStatus
      );
      if (willStatus === 'NOT_STARTED') {
        return convertModuleScoreToProgress([], ['NOT_STARTED'], isFlagged);
      }

      const user = rootGetters['user/contacts/userDetails'];
      const partner = rootGetters['user/contacts/userPartner'];
      const userIsAustralian = rootGetters['user/contacts/userIsAustralian'];
      const target = [];
      const score = [];

      target.push('fullName');
      const hasEnteredFullName = !!(user.firstName && user.lastName);
      score.push(hasEnteredFullName);

      target.push('has_alt_name');
      const hasAnsweredHasAltName = isStrictBoolean(data.meta.has_alt_name);
      score.push(hasAnsweredHasAltName);

      target.push('altName');
      const hasAltName = data.meta.has_alt_name;
      const hasEnteredAltName = !hasAnsweredHasAltName
        ? false
        : hasAltName
          ? !!(user.altFirstName && user.altLastName)
          : true;
      score.push(hasEnteredAltName);

      target.push('dateOfBirth');
      score.push(!!user.dateOfBirth);

      target.push('residentialAddress');
      score.push(
        !!(
          user.residentialAddress.streetAddress &&
          user.residentialAddress.locality &&
          (user.residentialAddress.region || !userIsAustralian) &&
          user.residentialAddress.postcode &&
          user.residentialAddress.country
        )
      );

      target.push('has_partner');
      const hasAnsweredHasPartner = isStrictBoolean(data.meta.has_partner);
      score.push(hasAnsweredHasPartner);

      target.push('hasListedPartner');
      const hasPartner = data.meta.has_partner;
      const hasListedPartner = !hasAnsweredHasPartner
        ? false
        : hasPartner
          ? !!partner
          : true;
      score.push(hasListedPartner);

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const executorsProgress = () => {
      const executors = rootGetters['will/executors'];
      const primaryExecutors = executors.filter(
        (executor) => executor.type === 'primary'
      );
      const backupExecutors = executors.filter(
        (executor) => executor.type === 'backup'
      );
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('EXECUTORS'),
        willStatus
      );

      const target = [];
      const score = [];

      const executorsOption = data.meta.executors_option;
      const hasAnsweredExecutorsOption = !!executorsOption;

      const hasBackupExecutor = data.meta.has_backup_executor;
      const hasAnsweredBackupExecutor = isStrictBoolean(hasBackupExecutor);
      target.push('executors_option');
      score.push(hasAnsweredExecutorsOption);

      switch (executorsOption) {
        case 'professional': {
          target.push('professional_executor_terms_agreed_at');
          score.push(!!data.meta.professional_executor_terms_agreed_at);

          target.push('backupExecutors');
          score.push(!!backupExecutors.length);

          break;
        }
        case 'friendsFamilyAndProfessional': {
          target.push('primaryExecutors');
          score.push(!!primaryExecutors.length);

          target.push('professional_executor_terms_agreed_at');
          score.push(!!data.meta.professional_executor_terms_agreed_at);

          break;
        }
        case 'friendsFamily': {
          target.push('primaryExecutors');
          score.push(!!primaryExecutors.length);

          target.push('hasListedAllBackupExecutors');
          const hasListedAllBackupExecutors = !hasAnsweredBackupExecutor
            ? false
            : hasBackupExecutor
              ? !!backupExecutors.length
              : true;
          score.push(hasListedAllBackupExecutors);

          break;
        }
        default: {
          target.push('hasCompletedListingBackupExecutors');
          score.push(
            !hasAnsweredBackupExecutor
              ? false
              : hasBackupExecutor
                ? !!backupExecutors.length
                : true
          );
          break;
        }
      }

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const estateProgress = () => {
      const beneficiaries = rootGetters['will/beneficiaries'];
      const target = [];
      const score = [];
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('ESTATE'),
        willStatus
      );

      const estateSplitOption = data.meta.estate_split;
      const hasAnsweredEstateSplitOption = !!estateSplitOption;

      target.push('estate_split');
      score.push(hasAnsweredEstateSplitOption);

      target.push('hasPrimaryBeneficiaries');
      const hasPrimaryBeneficiaries = !!beneficiaries.length;
      score.push(hasPrimaryBeneficiaries);

      target.push('validPrimaryDistribution');
      const validPrimaryDistribution =
        hasPrimaryBeneficiaries &&
        (data.isPrimaryEstateSplitEvenly ||
          beneficiaries.every((beneficiary) => {
            return beneficiary.distribution && beneficiary.distribution !== '0';
          }));
      score.push(validPrimaryDistribution);

      target.push('hasBackupBeneficiaries');
      const hasBackupBeneficiaries =
        validPrimaryDistribution &&
        beneficiaries.some((beneficiary) => {
          return (
            !!beneficiary.charity ||
            !!beneficiary.cause ||
            !!beneficiary.backup.length ||
            ['children', 'remaining'].includes(
              beneficiary.meta.estate_backup_split
            )
          );
        });
      score.push(hasBackupBeneficiaries);

      target.push('validBackupDistribution');
      const validBackupDistribution =
        hasBackupBeneficiaries &&
        beneficiaries.every((beneficiary) => {
          const beneficiaryIsACharityOrCause =
            !!beneficiary.charity || !!beneficiary.cause;
          const beneficiaryIsNonSpecific = ['children', 'remaining'].includes(
            beneficiary.meta.estate_backup_split
          );
          const beneficiaryIsEvenSplit =
            !!beneficiary.backup.length &&
            beneficiary.isBackupEstateSplitEvenly;
          const beneficiarySplitIsSpecified =
            !!beneficiary.backup.length &&
            beneficiary.backup.every((backup) => {
              return backup.distribution && backup.distribution !== '0';
            });
          const valid =
            beneficiaryIsACharityOrCause ||
            beneficiaryIsNonSpecific ||
            beneficiaryIsEvenSplit ||
            beneficiarySplitIsSpecified;
          return valid;
        });
      score.push(validBackupDistribution);

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const assetsProgress = () => {
      const assets = rootGetters['will/assets'];
      const liabilities = rootGetters['will/liabilities'];
      const target = [];
      const score = [];
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('ASSETS'),
        willStatus
      );

      target.push('has_assets');
      const hasAnsweredHasAssets = isStrictBoolean(data.meta.has_assets);
      score.push(hasAnsweredHasAssets);

      target.push('assets');
      const hasAssets = data.meta.has_assets;
      const hasListedAssets = !hasAnsweredHasAssets
        ? false
        : hasAssets
          ? !!assets.length
          : true;
      score.push(hasListedAssets);

      target.push('has_liabilities');
      const hasAnsweredHasLiabilities = isStrictBoolean(
        data.meta.has_liabilities
      );
      score.push(hasAnsweredHasLiabilities);

      target.push('liabilities');
      const hasLiabilities = data.meta.has_liabilities;
      const hasListedLiabilities = !hasAnsweredHasLiabilities
        ? false
        : hasLiabilities
          ? !!liabilities.length
          : true;
      score.push(hasListedLiabilities);

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const guardiansProgress = () => {
      const children = rootGetters['user/contacts/userChildren'];
      const hasChildrenUnder18 = children.some(
        (userChild) => !userChild.over18
      );
      const guardians = rootGetters['will/guardians'];
      const primaryGuardians = guardians.filter(
        (guardian) => guardian.type === 'primary'
      );
      const backupGuardians = guardians.filter(
        (guardian) => guardian.type === 'backup'
      );
      const pets = rootGetters['user/pets/pets'];
      const petGuardians = pets.filter((pet) => !!pet.guardianDirectoryPerson);
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('FAMILY'),
        willStatus
      );

      const target = [];
      const score = [];

      target.push('has_children');
      const hasAnsweredHasChildren = isStrictBoolean(data.meta.has_children);
      score.push(hasAnsweredHasChildren);

      target.push('hasListedAllChildren');
      const hasChildren = data.meta.has_children;
      const hasListedAllChildren = !hasAnsweredHasChildren
        ? false
        : hasChildren
          ? !!children.length
          : true;
      score.push(hasListedAllChildren);

      if (hasChildrenUnder18) {
        const hasAnsweredHasPrimaryGuardian = isStrictBoolean(
          data.meta.has_primary_guardian
        );
        target.push('has_primary_guardian');
        score.push(hasAnsweredHasPrimaryGuardian);

        target.push('hasListedAllPrimaryGuardians');
        const hasPrimaryGuardian = data.meta.has_primary_guardian;
        const hasListedAllPrimaryGuardians = !hasAnsweredHasPrimaryGuardian
          ? false
          : hasPrimaryGuardian
            ? !!primaryGuardians.length
            : true;
        score.push(hasListedAllPrimaryGuardians);

        if (hasPrimaryGuardian) {
          const hasAnsweredHasBackupGuardian = isStrictBoolean(
            data.meta.has_backup_guardian
          );
          target.push('has_backup_guardian');
          score.push(hasAnsweredHasBackupGuardian);

          target.push('hasListedAllBackupGuardians');
          const hasBackupGuardians = data.meta.has_backup_guardian;
          const hasListedAllBackupGuardians = !hasAnsweredHasBackupGuardian
            ? false
            : hasBackupGuardians
              ? !!backupGuardians.length
              : true;
          score.push(hasListedAllBackupGuardians);
        } else {
          target.push('has_backup_guardian');
          score.push(hasListedAllPrimaryGuardians);
          target.push('hasListedAllBackupGuardians');
          score.push(hasListedAllPrimaryGuardians);
        }
      } else {
        target.push('has_primary_guardian');
        score.push(hasListedAllChildren);
        target.push('hasListedAllPrimaryGuardians');
        score.push(hasListedAllChildren);
        target.push('has_backup_guardian');
        score.push(hasListedAllChildren);
        target.push('hasListedAllBackupGuardians');
        score.push(hasListedAllChildren);
      }

      target.push('has_pets');
      const hasAnsweredHasPets = isStrictBoolean(data.meta.has_pets);
      score.push(hasAnsweredHasPets);

      target.push('hasListedAllPets');
      const hasPets = data.meta.has_pets;
      const hasListedAllPets = !hasAnsweredHasPets
        ? false
        : hasPets
          ? !!pets.length
          : true;
      score.push(hasListedAllPets);

      if (pets.length) {
        const hasAnsweredHasPetGuardians = isStrictBoolean(
          data.meta.has_pet_guardians
        );
        target.push('has_pet_guardians');
        score.push(hasAnsweredHasPetGuardians);

        target.push('hasListedAllPetGuardians');
        const hasPetGuardians = data.meta.has_pet_guardians;
        const hasListedAllPetGuardians = !hasAnsweredHasPetGuardians
          ? false
          : hasPetGuardians
            ? pets.length === petGuardians.length
            : true;
        score.push(hasListedAllPetGuardians);
      } else {
        target.push('has_pet_guardians');
        score.push(hasListedAllPets);
        target.push('hasListedAllPetGuardians');
        score.push(hasListedAllPets);
      }

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const giftsProgress = () => {
      const gifts = rootGetters['will/gifts'];
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('GIFTS'),
        willStatus
      );

      const giftsToCharity = gifts.filter((gift) => !!gift.charity);

      const target = [];
      const score = [];

      target.push('has_gifts');
      const hasAnsweredGifts = isStrictBoolean(data.meta.has_gifts);
      score.push(hasAnsweredGifts);

      target.push('gifts');
      const hasGifts = data.meta.has_gifts;
      const hasListedAllGifts = !hasAnsweredGifts
        ? false
        : hasGifts
          ? !!gifts.length
          : true;
      score.push(hasListedAllGifts);

      target.push('charity_in_gifts');
      const hasAnsweredGiftsToCharity = isStrictBoolean(
        data.meta.charity_in_gifts
      );
      score.push(hasAnsweredGifts && hasAnsweredGiftsToCharity);

      target.push('gifts');
      const hasGiftsToCharity = data.meta.charity_in_gifts;
      const hasListedAllGiftsToCharity = !hasAnsweredGiftsToCharity
        ? false
        : hasGiftsToCharity
          ? !!giftsToCharity.length
          : true;
      score.push(hasListedAllGiftsToCharity);

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const funeralProgress = () => {
      const willStatus = rootGetters.willStatus;
      const isFlagged = showFlaggedIcons(
        isPlanModuleFlagged('FUNERAL'),
        willStatus
      );

      const target = [];
      const score = [];

      target.push('funeral_type');
      score.push(!!data.meta.funeral_type);

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const reviewProgress = () => {
      const willStatus = rootGetters.willStatus;
      const isFlagged = false;

      const target = [];
      const score = [];

      target.push('willSubmitted');
      score.push(
        ['AWAITING_APPROVAL', 'APPROVED', 'FLAGGED'].includes(willStatus)
      );

      return convertModuleScoreToProgress(score, target, isFlagged);
    };

    const moduleProgress = {
      aboutYourself: aboutYourselfProgress(),
      guardians: guardiansProgress(),
      estate: estateProgress(),
      gifts: giftsProgress(),
      assets: assetsProgress(),
      executors: executorsProgress(),
      funeral: funeralProgress(),
      review: reviewProgress(),
    };

    commit('setWillProgress', {
      modules: moduleProgress,
      ...convertModuleScoresToOverallProgress(moduleProgress),
    });
  },
  setPoaProgress({ commit, rootGetters }) {
    const user = rootGetters['user/contacts/userDetails'];
    const poaStatus = rootGetters['user/poa/status'];
    const data = rootGetters['user/poa/data'];
    const attorneys = rootGetters['user/poa/attorneys'];

    const aboutYourselfProgress = () => {
      if (!poaStatus) {
        return convertModuleScoreToProgress([], ['NOT_STARTED']);
      }
      const target = [];
      const score = [];

      target.push('fullName');
      const hasEnteredFullName = !!(user.firstName && user.lastName);
      score.push(hasEnteredFullName);

      target.push('dateOfBirth');
      score.push(!!user.dateOfBirth);

      target.push('residentialAddress');
      score.push(
        !!(
          user.residentialAddress.streetAddress &&
          user.residentialAddress.locality &&
          user.residentialAddress.region &&
          user.residentialAddress.postcode &&
          user.residentialAddress.country
        )
      );

      return convertModuleScoreToProgress(score, target);
    };
    const financialProgress = () => {
      const meta = data.meta.financial;
      if (!meta) {
        return convertModuleScoreToProgress([], ['NOT_STARTED']);
      }

      const statesWithoutSubstitutes = ['SA', 'TAS'];
      const statesWithoutSpending = ['QLD', 'ACT', 'TAS', 'NT'];

      const inclusions = rootGetters['user/poa/inclusions'];

      const target = [];
      const score = [];

      target.push('has_attorney');
      const hasAttorney = meta.has_attorney;
      const hasAnsweredHasAttorney = isStrictBoolean(hasAttorney);
      score.push(hasAnsweredHasAttorney);

      if (hasAnsweredHasAttorney && !hasAttorney) {
        return convertModuleScoreToProgress(
          ['HAS_STARTED'],
          ['NOT_INTERESTED']
        );
      }

      target.push('hasListedAllAttorneys');
      const hasListedAllAttorneys = !hasAnsweredHasAttorney
        ? false
        : hasAttorney
          ? !!attorneys.financial.primary.length
          : true;
      score.push(hasListedAllAttorneys);

      if (!statesWithoutSubstitutes.includes(user.residentialAddress.region)) {
        target.push('has_substitute');
        const hasSubstitute = meta.has_substitute;
        const hasAnsweredHasSubstitute = isStrictBoolean(hasSubstitute);
        score.push(hasAnsweredHasSubstitute);

        target.push('hasListedAllSubstitutes');
        const hasListedAllSubstitutes = !hasAnsweredHasSubstitute
          ? false
          : hasSubstitute
            ? !!attorneys.financial.secondary.length
            : true;
        score.push(hasListedAllSubstitutes);
      }

      if (!statesWithoutSpending.includes(user.residentialAddress.region)) {
        target.push('has_inclusions');
        const hasInclusions = meta.has_inclusions;
        const hasAnsweredHasInclusions = isStrictBoolean(hasInclusions);
        score.push(hasAnsweredHasInclusions);

        target.push('hasAddedAllInclusions');
        const hasAddedAllInclusions = !hasAnsweredHasInclusions
          ? false
          : hasInclusions
            ? !!inclusions.length
            : true;
        score.push(hasAddedAllInclusions);
      }

      target.push('has_limitations');
      const hasLimitations = meta.has_limitations;
      const hasAnsweredHasLimitations = isStrictBoolean(hasLimitations);
      score.push(hasAnsweredHasLimitations);

      target.push('hasAddedAllLimitations');
      const hasAddedAllLimitations = !hasAnsweredHasLimitations
        ? false
        : hasLimitations
          ? !!meta.limitations
          : true;
      score.push(hasAddedAllLimitations);

      return convertModuleScoreToProgress(score, target);
    };

    const medicalProgress = () => {
      const meta = data.meta.medical;
      if (!meta) {
        return convertModuleScoreToProgress([], ['NOT_STARTED']);
      }
      const statesWithoutSubstitutes = ['SA'];

      const target = [];
      const score = [];

      target.push('has_attorney');
      const hasAttorney = meta.has_attorney;
      const hasAnsweredHasAttorney = isStrictBoolean(hasAttorney);
      score.push(hasAnsweredHasAttorney);

      if (hasAnsweredHasAttorney && !hasAttorney) {
        return convertModuleScoreToProgress(
          ['HAS_STARTED'],
          ['NOT_INTERESTED']
        );
      }

      target.push('hasListedAllAttorneys');
      const hasListedAllAttorneys = !hasAnsweredHasAttorney
        ? false
        : hasAttorney
          ? !!attorneys.medical.primary.length
          : true;
      score.push(hasListedAllAttorneys);

      if (!statesWithoutSubstitutes.includes(user.residentialAddress.region)) {
        target.push('has_substitute');
        const hasSubstitute = meta.has_substitute;
        const hasAnsweredHasSubstitute = isStrictBoolean(hasSubstitute);
        score.push(hasAnsweredHasSubstitute);

        target.push('hasListedAllSubstitutes');
        const hasListedAllSubstitutes = !hasAnsweredHasSubstitute
          ? false
          : hasSubstitute
            ? !!attorneys.medical.secondary.length
            : true;
        score.push(hasListedAllSubstitutes);
      }

      target.push('has_limitations');
      const hasLimitations = meta.has_limitations;
      const hasAnsweredHasLimitations = isStrictBoolean(hasLimitations);
      score.push(hasAnsweredHasLimitations);

      target.push('hasAddedAllLimitations');
      const hasAddedAllLimitations = !hasAnsweredHasLimitations
        ? false
        : hasLimitations
          ? !!meta.limitations
          : true;
      score.push(hasAddedAllLimitations);

      target.push('has_preferences');
      const hasPreferences = meta.has_preferences;
      const hasAnsweredHasPreferences = isStrictBoolean(hasPreferences);
      score.push(hasAnsweredHasPreferences);

      target.push('hasAddedAllPreferences');
      const hasAddedAllPreferences = !hasAnsweredHasPreferences
        ? false
        : hasPreferences
          ? !!meta.preferences
          : true;
      score.push(hasAddedAllPreferences);

      return convertModuleScoreToProgress(score, target);
    };

    const reviewProgress = () => {
      const target = [];
      const score = [];

      target.push('poaSubmitted');
      score.push(
        ['AWAITING_APPROVAL', 'APPROVED', 'FLAGGED'].includes(poaStatus)
      );

      return convertModuleScoreToProgress(score, target);
    };

    const moduleProgress = {
      aboutYourself: aboutYourselfProgress(),
      financial: financialProgress(),
      medical: medicalProgress(),
      review: reviewProgress(),
    };

    commit('setPoaProgress', {
      modules: moduleProgress,
      ...convertModuleScoresToOverallProgress(moduleProgress),
    });
  },
};
