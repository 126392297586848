import { getPathWithoutLocale } from '@/utilities/index';

const forbiddenPathsForNzl = [
  '/poa',
  '/executors/professional-terms',
  '/executors/options',
];

export default function ({
  redirect,
  store,
  route,
  localePath,
  i18n,
  switchLocalePath,
}) {
  const affiliateSlug = store.getters['application/affiliate/slug'];
  const affiliateFeatures = store.getters['application/affiliate/features'];
  const country = store.getters['user/contacts/userCountry'].toLowerCase();

  // we get path to check if it includes affiliate slug
  // checking fullPath may cause issues as affiliate slug can be in the query params
  const { path, fullPath } = route;

  // check if there is a mismatch in user's country and the current url locale
  if (country !== i18n.locale) {
    i18n.locale = country;
  }

  // TODO: Improve this to handle if dashboard is disabled
  if (
    isAllowedAffiliateRedirect(fullPath, affiliateFeatures) &&
    isAllowedLocaleRedirect(path, i18n.locale)
  ) {
    const pathWithoutLocale = getPathWithoutLocale(fullPath, i18n.localeCodes);
    // making sure the affiliate slug is not in a query param
    if (!path.includes(affiliateSlug)) {
      const redirectPath = `/${affiliateSlug}${pathWithoutLocale}`;
      return redirect(localePath(redirectPath));
    }
    return switchLocalePath(fullPath);
  } else {
    const redirectPath = `/${affiliateSlug}/`;
    return redirect(localePath(redirectPath));
  }
}

function isAllowedAffiliateRedirect(path, features) {
  if (!path) {
    return false;
  }
  if (path.includes('/will')) {
    return features.willEnabled;
  }
  if (path.includes('/power-of-attorney') || path.includes('/poa')) {
    return features.powerOfAttorneyEnabled;
  }
  if (path.includes('/end-of-life')) {
    return features.endOfLifeEnabled;
  }
  if (path.includes('/gifts') && !path.includes('/will/gifts')) {
    return features.giftEnabled;
  }
  return true;
}

function isAllowedLocaleRedirect(path, locale) {
  if (locale === 'nz') {
    const pathIsForbidden = forbiddenPathsForNzl.some((forbiddenPath) => {
      if (forbiddenPath === '/poa' && path.includes('/onboarding')) {
        return false;
      }
      return path.includes(forbiddenPath);
    });
    return !pathIsForbidden;
  }
  return true;
}
